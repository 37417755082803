import React from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCoffee } from "@fortawesome/free-solid-svg-icons"

const NavItem = ({
  text = "",
  link = "/",
  icon = faCoffee,
  f,
  muiIcon = false,
  down = false,
}) => {
  return (
    <li onClick={f} className={`sfb-nav-item ${down ? "down" : null}`}>
      <Link to={link || "/"}>
        <div className="sfb-nav-link">
          {icon && <FontAwesomeIcon icon={icon} />}
          <span className="sfb-link-text">{text}</span>
        </div>
      </Link>
    </li>
  )
}

export default NavItem
