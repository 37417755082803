import React, { useContext } from "react"
import { thousand } from "../functions"
import Graf from "./Graf"
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Divider,
  Grid,
} from "@material-ui/core"
import { NyckeltalMonth, Period, Nyckeltal } from "../../../hooks/Types"
import { format } from "date-fns"
import { PeriodContext } from "../CompanyApp"
import { sv } from "date-fns/locale"
import {
  blue,
  cyan,
  green,
  orange,
  purple,
  red,
} from "@material-ui/core/colors"

type ntName = "res" | "oms" | "ebitda" | "bv" | "pk" | "kost"

const useStyles = makeStyles({
  titleText: {
    fontWeight: 700,
  },
  text: {
    fontWeight: 600,
  },
  res: {
    background: `linear-gradient(135deg, ${blue[100]} 0%,${blue[500]} 100%);`,
  },
  oms: {
    background: `linear-gradient(135deg, ${green[100]} 0%,${green[500]} 100%);`,
    // background: "linear-gradient(135deg, #00e8f2 0%,#95ea00 100%);",
  },
  ebitda: {
    background: `linear-gradient(135deg, ${purple[100]} 0%,${purple[500]} 100%);`,
    // background: "linear-gradient(135deg, #FF57B9 0%,#A704FD 100%);",
  },
  bv: {
    background: `linear-gradient(135deg, ${cyan[100]} 0%,${cyan[500]} 100%);`,
    // background: "linear-gradient(135deg, #42e695 0%,#3bb2b8 100%);",
  },
  pk: {
    background: `linear-gradient(135deg, ${orange[100]} 0%,${orange[500]} 100%);`,
    // background: "linear-gradient(135deg, #fcdf8a 0%,#f38381 100%);",
  },
  kost: {
    background: `linear-gradient(135deg, ${red[100]} 0%,${red[500]} 100%);`,
    // background: "linear-gradient(135deg, #F36265 0%,#961276 100%);",
  },
})

export const NyckeltalCard = ({ nyckeltal }: { nyckeltal: Nyckeltal }) => {
  const classes = useStyles()
  const { title, name, utfall, budget } = nyckeltal
  const avvikelse = utfall - budget

  return (
    <Grid item xs={6} md={4} lg={3} xl={2}>
      <Card className={classes?.[name as ntName] || ""}>
        <CardContent>
          <Typography className={classes.titleText} variant="overline">
            {title}
          </Typography>
          <Typography
            className={classes.text}
            variant="body2"
          >{`Utfall: ${thousand(-utfall)}`}</Typography>
          <Typography
            className={classes.text}
            variant="body2"
          >{`Budget: ${thousand(-budget)}`}</Typography>
          <Typography
            className={classes.text}
            variant="body2"
          >{`Avvikelse: ${thousand(-avvikelse)}`}</Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export const NyckeltalGrafer = ({
  nyckeltal,
}: {
  nyckeltal: NyckeltalMonth[]
}) => {
  if (!nyckeltal.length) return null
  const nt = nyckeltal[0].nyckeltal.map((tal) => {
    const months = nyckeltal.map((month) => {
      const currTal = month.nyckeltal.find((t) => t.name === tal.name)
      return {
        utfall: currTal?.utfall || 0,
        budget: currTal?.budget || 0,
        month: month.month,
      }
    })
    return {
      months,
      name: tal.name,
      title: tal.title,
    }
  })

  return (
    <Grid container spacing={3} item xs={12}>
      {nt && nt.map((tal, i) => <Graf key={i} data={tal} />)}
    </Grid>
  )
}

export const NyckeltalCards = ({ nyckeltal }: { nyckeltal: Nyckeltal[] }) => {
  const [period] = useContext(PeriodContext) as [Period]
  const { start, end } = period

  return (
    <Grid container spacing={3} item xs={12}>
      <Grid item xs={12}>
        <Typography align="center" variant="overline">
          {start.toDateString() === end.toDateString()
            ? format(start, "MMMM yyyy", { locale: sv })
            : `${format(start, "MMMM yyyy", { locale: sv })} - ${format(
                end,
                "MMMM yyyy",
                { locale: sv }
              )}`}
        </Typography>
        <Divider />
      </Grid>
      {nyckeltal &&
        nyckeltal.map((tal, i) => <NyckeltalCard nyckeltal={tal} key={i} />)}
    </Grid>
  )
}
