import React, { createContext, useContext } from "react"
import { CompanyContext } from ".."
import {
  Access,
  Accounts,
  AccountsDoc,
  BalanceMonth,
  Period,
} from "../../../hooks/Types"
import { OptionsContext, PeriodContext } from "../CompanyApp"
import Page from "../Components/Page"
import { format } from "date-fns"
import { useParams } from "react-router-dom"
import firebase from "../../../config/firebaseConfig"
import { Column } from "./LayoutModules"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import { Budget } from "../../../Types/Database"
const db = firebase.firestore()

export const FinancialContext = createContext<{
  data?: Data
  accounts?: Accounts
}>({})

const FinancialStatement = ({
  cols,
  title,
  children,
}: {
  cols: [Column, Column, Column]
  title: string
  children: JSX.Element
}) => {
  const [period] = useContext(PeriodContext) as [Period]
  const company = useContext(CompanyContext) as Access
  const [options] = useContext(OptionsContext)

  const start = parseInt(format(period.start, "yyyyMM"))
  const end = parseInt(format(period.end, "yyyyMM"))

  const { cid } = useParams() as { cid: string }
  const yid = company.financialyears.find(
    (year) =>
      parseInt(year.from.slice(0, 6)) <= end &&
      parseInt(year.to.slice(0, 6)) >= end
  )?.id

  const currentYear = company.financialyears.find(
    (y) =>
      parseInt(y.from.slice(0, 6)) <= start &&
      parseInt(y.to.slice(0, 6)) >= start
  )
  const startYid = currentYear!.id

  const balanceRef = db
    .collection(`companies/${cid}/balances`)
    .where("month", ">=", start)
    .where("month", "<=", end)
    .orderBy("month", "asc")
  const [balances, loadingBalances] = useCollectionData<BalanceMonth>(
    balanceRef
  )

  const prevYearBalanceRef = db
    .collection(`companies/${cid}/balances`)
    .where("month", ">=", start - 100)
    .where("month", "<=", end - 100)
    .orderBy("month", "asc")
  const [prevYearBalances, loadingPrevYearBalances] = useCollectionData<
    BalanceMonth
  >(prevYearBalanceRef)

  const accountRef = db
    .collection(`companies/${cid}/accounts`)
    .where("yid", "==", yid)
  const [accounts, loadingAccounts] = useCollectionData<AccountsDoc>(accountRef)

  const budgetId = options?.budgetId
  const budgetRef = budgetId
    ? db.doc(`companies/${cid}/budgets/${budgetId}`)
    : null
  const [budget, loadingBudget] = useDocumentData<Budget>(budgetRef)

  const ibRef =
    cols.includes("ib") &&
    db
      .collection(`companies/${cid}/balances`)
      .where("month", "<", start)
      .where("yid", "==", startYid)
      .orderBy("month", "asc")
  const [ibBalances, loadingIbBalances] = useCollectionData<BalanceMonth>(ibRef)

  if (
    loadingAccounts ||
    loadingBalances ||
    loadingPrevYearBalances ||
    loadingBudget ||
    loadingIbBalances ||
    (budgetId && !budget)
  )
    return null

  const getBudget = (account: number) => {
    if (!budget) return 0
    const value = budget.values
      .find((a) => a.nr === account)
      .values.reduce(
        (a, c) => a + (c.month >= start && c.month <= end ? c.value : 0),
        0
      )
    return value
  }

  const getValues = (cols: Column[], account: number) => {
    const [utfall, defaultBudget] = balances.reduce(
      (a, c) => {
        const cAccount = c.accounts.find((acc) => acc.number === account)
        const [aUtfall, aBudget] = a
        return cAccount
          ? [aUtfall + cAccount.utfall, aBudget + cAccount.budget]
          : [...a]
      },
      [0, 0]
    )

    const prevY =
      cols.includes("fgå") &&
      prevYearBalances.reduce((a, c) => {
        const cAccount = c.accounts.find((acc) => acc.number === account)
        return cAccount ? a + cAccount.utfall : a
      }, 0)

    const ib =
      (cols.includes("ib") &&
        ibBalances?.reduce((a, c) => {
          const cAccount = c.accounts.find((acc) => acc.number === account)
          return cAccount ? a + cAccount.utfall : a
        }, 0)) ||
      0 + accounts?.[0]?.accounts.find((acc) => acc.nr === account)?.ib

    return cols.map((col) => {
      if (col[0] === "avv") {
        //@ts-ignore
        const values = getValues(col, account)
        return values[1] - values[2]
      }
      switch (col) {
        case "utf":
          return utfall
        case "bud":
          return budgetId ? getBudget(account) : defaultBudget
        case "fgå":
          return prevY
        case "diff":
          return utfall
        case "ib":
          return ib
        case "ub":
          return ib + utfall

        default:
          return 0
      }
    })
  }

  //Formatera data
  const data: Data = {
    cols,
    accounts: accounts[0].accounts
      .map((account) => {
        const { nr, name } = account
        const values = getValues(cols, nr)
        return {
          nr,
          name,
          values,
        }
      })
      .filter((account) => !account.values.every((val) => val === 0)),
  }

  return (
    <FinancialContext.Provider
      value={{
        data,
        accounts: accounts[0].accounts,
        // balances,
        // cols,
        // lastYearBals,
        // prevBalances,
        // accountBalances,
      }}
    >
      <Page title={title} maxWidth="xl">
        {children}
      </Page>
    </FinancialContext.Provider>
  )
}

export default FinancialStatement

interface Data {
  accounts: {
    nr: number
    name: string
    values: number[]
  }[]
  cols: Column[]
}
