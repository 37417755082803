import React, { useState, useEffect, useCallback, useContext } from "react"
import "./auth.scss"
import {
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  ListItemAvatar,
  Avatar,
  InputAdornment,
  FormControlLabel,
  Switch,
  Button,
  Link,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import Page from "../company/CompanyRouter/Components/Page"
import { useFunction } from "../hooks/customHooks.ts"
import firebase from "../config/firebaseConfig"
import { UserContext } from "../App.tsx"

const URL = "https://app.smaforetagsbyran.se/signup/"

const defaultModules = ["base", "avvikelse"]

const AddUser = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [konsult, setKonsult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [selected, setSelected] = useState([])
  const [unselected, setUnselected] = useState([])
  const [searched, setSearched] = useState([])
  const [email, setEmail] = useState("")
  const [link, setLink] = useState(null)
  const user = useContext(UserContext)
  const uid = user?.uid
  const getCompany = useCallback((cid) => companies.find((c) => c.id === cid), [
    companies,
  ])
  const addUser = useFunction("addNewUser")
  useEffect(() => {
    if (companies.length) return
    const getLogo = async (company) => {
      const { id, logoFileName } = company
      try {
        const logoURL = await firebase
          .storage()
          .ref(`${id}/${logoFileName}`)
          .getDownloadURL()
        return logoURL
      } catch {
        return ""
      }
    }
    const mapCompanies = async (allCompanies) => {
      const companies = allCompanies.map(async (company) => {
        const logo = await getLogo(company)
        return { ...company, logo }
      })

      setCompanies(await Promise.all(companies))
    }
    const unsubscribe = firebase
      .firestore()
      .collectionGroup("access")
      .where("uid", "==", uid)
      .onSnapshot((snapshot) => {
        const allCompanies = snapshot.docs.map((doc) => doc.data())
        mapCompanies(allCompanies)
      })
    return unsubscribe
  }, [companies, uid])
  useEffect(() => {
    setUnselected(
      companies
        .map((c) => c.id)
        .sort((a, b) => {
          return getCompany(b).name > getCompany(a).name ? -1 : 1
        })
    )
    setSearched(companies.map((c) => c.id))
  }, [companies, getCompany])

  useEffect(() => {
    const regex = new RegExp(searchTerm, "i")
    setSearched(
      unselected.filter((cid) =>
        companies.find((c) => c.id === cid).name.match(regex)
      )
    )
  }, [searchTerm, unselected, companies])

  const handleSelect = (cid, selected) => () => {
    if (loading) return null
    if (selected) {
      setUnselected((unselected) => unselected.filter((id) => id !== cid))
      setSelected((selected) =>
        [...selected, cid].sort((a, b) => {
          return getCompany(b).name > getCompany(a).name ? -1 : 1
        })
      )
    } else {
      setSelected((unselected) => unselected.filter((id) => id !== cid))
      setUnselected((selected) =>
        [...selected, cid].sort((a, b) => {
          return getCompany(b).name > getCompany(a).name ? -1 : 1
        })
      )
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const role = konsult ? "konsult" : "user"
    const access = selected.map((cid) => {
      const companyData = companies.find((company) => company.id === cid)
      return {
        ...companyData,
        role,
        modules: defaultModules,
      }
    })
    const userData = {
      email,
      role,
      access,
    }
    const link = await addUser({ ...userData })
    setLink(`${URL}${link.data}`)
    setLoading(false)
    setEmail("")
    setKonsult(false)
    setSelected([])
    setUnselected(
      companies
        .map((c) => c.id)
        .sort((a, b) => {
          return getCompany(b).name > getCompany(a).name ? -1 : 1
        })
    )
  }
  return (
    <Page title="Lägg till användare">
      {link && (
        <Grid container justify="center" direction="column" align="center">
          <Typography align="center" variant="subtitle1" color="textPrimary">
            {`Användaren kan nu skapas via följande länk: `}
            <Link href={`${link}`}>{`${link}`}</Link>
          </Typography>
          {/* <Link
          href={`mailto:${linkEmail}?subject=${SUBJECT}&body=${BODY(link)}`}
        >
          Skicka mail
        </Link> */}
        </Grid>
      )}
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid item container xs={12} justify="space-between">
              <Grid item xs={9}>
                <TextField
                  disabled={loading}
                  fullWidth
                  label={"Mail"}
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  required
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={loading}
                      checked={konsult}
                      onChange={(e) => setKonsult(e.target.checked)}
                      name="konsult"
                    />
                  }
                  label="Konsult"
                  labelPlacement="bottom"
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={loading}
                  size={"large"}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Lägg till
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            fullWidth
            label="Sök företag"
            value={searchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography align="center" variant="h5">
            Företag
          </Typography>
          <Divider />
          <List>
            {searched.map((cid, i) => {
              const company = companies.find((c) => c.id === cid)
              return (
                <CompanyListItem
                  key={i}
                  cid={cid}
                  company={company}
                  handleSelect={handleSelect}
                  selected={true}
                />
              )
            })}
          </List>
        </Grid>
        <Grid item sx={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5}>
          <Typography align="center" variant="h5">
            Valda företag
          </Typography>
          <Divider />
          <List>
            {selected.map((cid, i) => {
              const company = companies.find((c) => c.id === cid)
              return (
                <CompanyListItem
                  key={i}
                  cid={cid}
                  company={company}
                  handleSelect={handleSelect}
                  selected={false}
                />
              )
            })}
          </List>
        </Grid>
      </Grid>
    </Page>
  )
}

export default AddUser

const CompanyListItem = ({ cid, company, handleSelect, selected }) => {
  const { logo } = company
  return (
    <ListItem button divider key={cid} onClick={handleSelect(cid, selected)}>
      {logo && (
        <ListItemAvatar>
          <Avatar src={logo} />
        </ListItemAvatar>
      )}
      <ListItemText
        inset={!logo}
        primary={company.name}
        secondary={company.orgnr}
      />
    </ListItem>
  )
}
