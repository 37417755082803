import React from "react"
import AvstämdSettings from "./AvstämdSettings.tsx"
import HandleUserAccess from "./HandleUsers"
import { Button, Grid } from "@material-ui/core"
import Page from "../Components/Page"
import { useFunction } from "../../../hooks/customHooks"
import { useParams } from "react-router-dom"
// import CompanySettings from "./CompanySettings"

const Settings = () => {
  const getVerUrl = useFunction("getVerUrl")
  const { cid } = useParams()
  const handleClick = async () => {
    const res = await getVerUrl({ cid })
    console.log(res)
  }
  return (
    <Page title="Inställningar">
      <Grid container spacing={8}>
        <Button onClick={handleClick}>Test</Button>
        <AvstämdSettings />
        <HandleUserAccess />
        {/* <CompanySettings /> */}
      </Grid>
    </Page>
  )
}

export default Settings
