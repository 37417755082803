import React, { Fragment, useContext, useEffect, useState } from "react"
import NavItem from "./NavItem"
import {
  faHome,
  faExchangeAlt,
  faCogs,
  faSignOutAlt,
  faMoneyBillWave,
  faSignInAlt,
  faUserPlus,
  faBuilding,
  faPlus,
  faCog,
  faBalanceScaleRight,
  faUser,
  faWallet,
  faKey,
} from "@fortawesome/free-solid-svg-icons"
import firebase from "../config/firebaseConfig"
import { useParams } from "react-router-dom"
import { CompanyContext, UserContext } from "../App.tsx"

export const NavItems = (props) => {
  const user = useContext(UserContext)
  const uid = user?.uid
  const konsult = user?.role === "konsult"
  const admin = user?.admin

  return (
    <Fragment>
      {!uid && (
        <NavItem text={"Logga in"} icon={faSignInAlt} link={"/signin"} />
      )}
      {!uid && (
        <NavItem text={"Ny användare"} icon={faUserPlus} link={"/signup"} />
      )}
      {uid && <NavItem text="Företag" icon={faBuilding} link="/companies" />}
      {uid && (
        <NavItem text="Lägg till företag" icon={faPlus} link="/addcompany" />
      )}
      {konsult && (
        <NavItem text="Lägg till användare" icon={faUserPlus} link="/adduser" />
      )}
      {admin && <NavItem text="Admin" icon={faCogs} link="/admin" />}
      {uid && <NavItem text={user?.displayName} icon={faUser} link="/u" down />}
      {uid && (
        <NavItem
          text={"Logga ut"}
          icon={faSignOutAlt}
          link={"/"}
          f={() => firebase.auth().signOut()}
        />
      )}
    </Fragment>
  )
}

export const CompanyItems = () => {
  const { cid } = useParams()
  const company = useContext(CompanyContext)
  const avstämd = company?.avstämd
  const konsult = company?.role === "konsult"
  const [modules, setModules] = useState([])
  const hasModule = (mod) => modules.includes(mod)

  useEffect(() => {
    if (company) {
      const { modules } = company
      setModules(modules)
    }
  }, [company])

  const rootPath = `/c/${cid}`
  const visa = konsult || avstämd

  return (
    <>
      <NavItem text="Startsida" icon={faHome} link={`${rootPath}/`} />
      {visa && hasModule("base") && (
        <NavItem
          text={"Balansrapport"}
          icon={faBalanceScaleRight}
          link={`${rootPath}/br`}
        />
      )}
      {visa && hasModule("base") && (
        <NavItem
          text={"Resultatrapport"}
          icon={faMoneyBillWave}
          link={`${rootPath}/rr`}
        />
      )}
      {visa && hasModule("base") && (
        <NavItem
          text={"Nyckeltal"}
          icon={faKey}
          link={`${rootPath}/nyckeltal`}
        />
      )}
      {visa && hasModule("avvikelse") && (
        <NavItem
          text="Avvikelseanalys"
          icon={faExchangeAlt}
          link={`${rootPath}/avvikelse`}
        />
      )}
      {hasModule("budget") && (
        <NavItem
          text="Budgetarbetsblad"
          icon={faWallet}
          link={`${rootPath}/budget`}
        />
      )}
      {konsult && (
        <NavItem
          text="Inställningar"
          icon={faCog}
          link={`${rootPath}/settings`}
        />
      )}
      <NavItem
        text={"Logga ut"}
        icon={faSignOutAlt}
        link={"/"}
        f={() => firebase.auth().signOut()}
        down
      />
    </>
  )
}
