import React, { createContext, useCallback, useEffect, useState } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Navbar from "./navigation/Navbar"
import SignIn from "./auth/SignIn"
import SignUp from "./auth/SignUp"
import Home from "./pages/Home"
import UserProfile from "./pages/UserProfile"
import CompanyMenu from "./companies"
import AddCompany from "./company/AddCompany"
import CompanyApp from "./company/CompanyRouter"
import AddUser from "./auth/AddUser"
import DateFnsUtils from "@date-io/date-fns"
import {
  Box,
  createMuiTheme,
  Paper,
  Slide,
  ThemeProvider,
  Typography,
} from "@material-ui/core"
import cyan from "@material-ui/core/colors/cyan"
import green from "@material-ui/core/colors/green"
import Admin from "./pages/Admin"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import sv from "date-fns/locale/sv"
import firebase from "./config/firebaseConfig"
import { useAuthState } from "react-firebase-hooks/auth"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { Access, User } from "./hooks/Types"
import { ConfirmEmail } from "./ConfirmEmail"
const db = firebase.firestore()

export const CompanyContext = createContext(null)
export const UserContext = createContext(null)

const theme = createMuiTheme({
  palette: {
    primary: cyan,
    secondary: green,
  },
})

const App = () => {
  const [cid, setCid] = useState(null)
  const [user] = useAuthState(firebase.auth())
  const handleCid = useCallback((value) => setCid(value), [])

  const [userData] = useDocumentData<User>(
    user?.uid && db.doc(`users/${user.uid}`)
  )
  const [company] = useDocumentData<Access>(
    user && cid && db.doc(`companies/${cid}/access/${user.uid}`)
  )
  const showConfirmEmail = Boolean(user) && !user.emailVerified

  const konsult = userData?.role === "konsult"
  const admin = userData?.admin

  const routes = user?.uid ? (
    <Switch>
      <Route path="/companies" component={CompanyMenu} />
      {konsult && <Route path="/addcompany" component={AddCompany} />}
      {konsult && <Route path="/adduser" component={AddUser} />}
      <Route path="/c/:cid">
        <CompanyApp setCid={handleCid} />
      </Route>
      <Route path="/u" component={UserProfile} />
      <Route path="/u/:uid" component={UserProfile} />
      {admin && <Route path="/admin" component={Admin} />}
      <Route path="/signup/:key" component={SignUp} />
      <Route path="*" component={Home} />
    </Switch>
  ) : (
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/signup/:key" component={SignUp} />
      <Route path="/" component={SignIn} />
    </Switch>
  )

  return (
    <BrowserRouter>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={sv}>
        <ThemeProvider theme={theme}>
          <UserContext.Provider value={userData}>
            <CompanyContext.Provider value={company}>
              <div className="App">
                <Navbar />
                {showConfirmEmail && <ConfirmEmail user={user} />}
                <main>{routes}</main>
              </div>
            </CompanyContext.Provider>
          </UserContext.Provider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  )
}

export default App
