import React, { useState } from "react"
import { Grid, Card, CardContent, IconButton } from "@material-ui/core"
import { Bar, LinearComponentProps } from "react-chartjs-2"
import { format } from "date-fns"
import { options } from "./grafPresets"
import { thousand } from "../functions"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"

const Graf = ({
  data,
}: {
  data: {
    months: {
      utfall: number
      budget: number
      month: number
    }[]
    name: string
    title: string
  }
}) => {
  const [fullscreen, setFullscreen] = useState(false)
  const { name, title, months } = data
  const utfallData = months.map((m) => -parseInt(m.utfall.toString()))
  const ackUtfallData = months.map(
    (m, i) =>
      -parseInt(
        months.reduce((a, c, j) => (j > i ? a : a + c.utfall), 0).toString()
      )
  )
  const budgetData = months.map((m) => -parseInt(m.budget.toString()))
  const ackBudgetData = months.map(
    (m, i) =>
      -parseInt(
        months.reduce((a, c, j) => (j > i ? a : a + c.budget), 0).toString()
      )
  )

  const maxMonth = Math.max(...utfallData, ...budgetData)
  const maxAck = Math.max(...ackUtfallData, ...ackBudgetData)
  const minMonth = Math.min(...utfallData, ...budgetData)
  const minAck = Math.min(...ackUtfallData, ...ackBudgetData)

  const monthRange = maxMonth - minMonth
  const ackRange = maxAck - minAck

  const monthMinRatio = minMonth / monthRange
  const monthMaxRatio = maxMonth / monthRange
  const ackMinRatio = minAck / ackRange
  const ackMaxRatio = maxAck / ackRange

  const minRatio =
    Math.min(monthMinRatio, ackMinRatio) < 0
      ? Math.min(monthMinRatio, ackMinRatio) * 1.2
      : 0
  const maxRatio =
    Math.max(monthMaxRatio, ackMaxRatio) > 0
      ? Math.max(monthMaxRatio, ackMaxRatio) * 1.2
      : 0

  const ackTicks = {
    min: minRatio * ackRange,
    max: maxRatio * ackRange,
  }
  const monthTicks = {
    min: minRatio * monthRange,
    max: maxRatio * monthRange,
  }

  const budgetExists = !budgetData.every((m) => !Boolean(m))
  const budgetDatasets = budgetExists
    ? [
        {
          data: budgetData,
          label: "Budget",
          yAxisID: "A",
          backgroundColor: "rgba(0,0,0,0.5)",
          order: 2,
        },
        {
          data: ackBudgetData,
          label: "Ack. budget",
          yAxisID: "B",
          backgroundColor: "rgba(0,0,0,0.5)",
          type: "line",
          fill: false,
          order: 1,
        },
      ]
    : []

  const chartData: LinearComponentProps = {
    data: {
      labels: months.map((m) => {
        const d = new Date(
          parseInt(m.month.toString().slice(0, 4)),
          parseInt(m.month.toString().slice(4, 6)) - 1,
          1
        )
        return format(d, "MMM yyyy")
      }),
      datasets: [
        {
          data: utfallData,
          label: "Utfall",
          yAxisID: "A",
          ...options[name],
          order: 3,
        },
        {
          data: ackUtfallData,
          label: "Ack. utfall",
          yAxisID: "B",
          ...options[name],
          type: "line",
          fill: false,
          order: 0,
        },
        ...budgetDatasets,
      ],

      // options: {
      //   color: (context) => {
      //     const index = context.dataIndex
      //     const value = context.dataset.data[index]
      //     return value < 0 ? "red" : "green"
      //   },
      // },
    },
    options: {
      title: {
        display: true,
        text: title,
      },
      scales: {
        yAxes: [
          {
            id: "A",
            type: "linear",
            position: "left",
            ticks: {
              ...monthTicks,
              callback: (v, i, tot) =>
                i === 0 || i === tot.length - 1 ? null : thousand(v),
            },
          },
          {
            id: "B",
            type: "linear",
            position: "right",
            ticks: {
              ...ackTicks,
              callback: (v, i, tot) =>
                i === 0 || i === tot.length - 1 ? null : thousand(v),
            },
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.datasets[tooltipItem.datasetIndex]?.label || ""
            return ` ${label}: ${thousand(tooltipItem.value)}`
          },
        },
        mode: "index",
        bodySpacing: 8,
        titleMarginBottom: 8,
        titleFontSize: 18,
        bodyFontSize: 16,
        bodyAlign: "left",
      },
    },
  }
  return (
    <Grid item xs={12} lg={fullscreen ? 12 : 6}>
      <Card style={{ position: "relative" }}>
        <IconButton
          onClick={() => setFullscreen(!fullscreen)}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        <CardContent>
          <Bar data={chartData.data} options={chartData.options} />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default Graf
