import React, { useContext, useEffect, useState } from "react"
import { useLogo } from "../../../hooks/customHooks"
import { NyckeltalCards, NyckeltalGrafer } from "./Nyckeltal"
import "./styles.scss"
import { Avatar, Grid, Typography } from "@material-ui/core"
import { CompanyContext } from "../../../App"
import { Access, Period, NyckeltalMonth } from "../../../hooks/Types"
import firebase from "../../../config/firebaseConfig"
import { PeriodContext } from "../CompanyApp"
import { format } from "date-fns"

const Home = () => {
  const company = useContext(CompanyContext) as Access
  const db = firebase.firestore()
  const { name, cid } = company
  const logo = useLogo(cid)

  const [nyckeltal, setNyckeltal] = useState<NyckeltalMonth[]>([])
  const [period] = useContext(PeriodContext) as [Period]
  const start = parseInt(format(period.start, "yyyyMM"))
  const end = parseInt(format(period.end, "yyyyMM"))

  useEffect(() => {
    const userRef = db
      .collection(`companies/${cid}/nyckeltal`)
      .where("month", ">=", start)
      .where("month", "<=", end)
    const ref =
      company.role === "konsult"
        ? userRef
        : userRef.where("avstämd", "==", true)
    const unsubscribe = ref.orderBy("month", "asc").onSnapshot((snap) => {
      const nyckeltalMonths = snap.docs.map((doc) =>
        doc.data()
      ) as NyckeltalMonth[]
      setNyckeltal(nyckeltalMonths)
    })
    return unsubscribe
  }, [cid, company.role, db, end, start])
  if (!nyckeltal.length) return null

  const nt = nyckeltal[0].nyckeltal.map((tal) => {
    const [utfall, budget] = nyckeltal.reduce(
      (a, c) => {
        const currTal = c.nyckeltal.find((nt) => nt.name === tal.name)
        return [(currTal?.utfall || 0) + a[0], (currTal?.budget || 0) + a[1]]
      },
      [0, 0]
    )
    return {
      ...tal,
      utfall,
      budget,
    }
  })

  return (
    <Grid container spacing={3}>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        alignContent="center"
        spacing={3}
      >
        {logo && (
          <Avatar
            style={{ height: 64, width: 64, marginRight: 16 }}
            src={logo}
          />
        )}
        <Typography variant="h2">{name}</Typography>
      </Grid>
      <NyckeltalCards nyckeltal={nt} />
      <NyckeltalGrafer nyckeltal={nyckeltal} />
    </Grid>
  )
}

export default Home
