import React, { createContext, useEffect, useRef, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import firebase from "../../../../../config/firebaseConfig"
import { useParams } from "react-router-dom"
import { useCells } from "./useCells"
import { Budget } from "../../../../../Types/Database"
import { CellType } from "../Cells/Cell"
import { useNavigateGrid } from "../Cells/hooks/useNavigateGrid"
import { LinearProgress } from "@material-ui/core"
const db = firebase.firestore()

interface Context {
  header: string[]
  cellsRef: React.MutableRefObject<{
    [id: string]: CellType
  }>
  addCell: (id: string, cell: any) => void
  grid: string[][]
}
export const CellStoreContext = createContext<Context>(null)

export const CellStore = ({ children }: { children: React.ReactNode }) => {
  const { cid, budgetId } = useParams<{ cid: string; budgetId: string }>()
  const budgetRef = db.doc(`companies/${cid}/budgets/${budgetId}`)
  const [budget] = useDocumentData<Budget>(budgetRef)

  //State med celler
  const cells = useRef<{ [id: string]: CellType }>({})

  //Hantera förändringar av values
  useEffect(() => {
    if (Boolean(budget)) {
      budget.values.forEach((account) => {
        account.values.forEach((month) => {
          const cell = cells?.[`A${account.nr}${month.month}`]
          console.log({ cell })
        })
      })
      console.log(budget.values)
    }
  }, [budget])

  //Extend cell metoder

  //Grid och gridförändringar (row, col, header)

  return (
    <CellStoreContext.Provider value={null}>
      {children}
    </CellStoreContext.Provider>
  )
}

// nst CellStore = ({ children }: { children: React.ReactNode }) => {
//   console.log("CellStore")
//   const [initialized, setInitialized] = useState(false)
//   const { cid, budgetId } = useParams<{ cid: string; budgetId: string }>()
//   const [budget] = useDocumentData<Budget>(
//     db.doc(`companies/${cid}/budgets/${budgetId}`)
//   )
//   const { header, addCell, grid, init, cellsRef } = useCells()
//   useEffect(() => {
//     if (budget && !initialized) {
//       init(budget)
//       setInitialized(true)
//     }
//   }, [budget, init, initialized])

//   useEffect(() => {

//   }, [budget.values])

//   useNavigateGrid(cellsRef, grid)

//   const context = {
//     header,
//     addCell,
//     grid,
//     cellsRef,
//   }

//   return (
//     <CellStoreContext.Provider
//       value={context}
//     >
//       {children}
//     </CellStoreContext.Provider>
//   )
// }
