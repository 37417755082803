import { TableCell, TableRow } from "@material-ui/core"
import React, { useContext } from "react"
import { FinancialContext } from "../../Rapporter/FinancialStatement"
import AccountRow from "./AccountRow"

const AccountGroup = ({
  accounts: [from, to],
  text,
}: {
  accounts: [number, number]
  text: string
}) => {
  const { data } = useContext(FinancialContext)
  const { accounts } = data
  const usedAccounts = accounts.filter(
    (account) => account.nr >= from && account.nr <= to
  )
  const sumText = `Summa ${text.toLowerCase()}`

  const sum = usedAccounts.reduce(
    (acc, curr) => acc.map((a, i) => a + (curr.values?.[i] || 0)),
    [0, 0, 0]
  )

  return (
    <>
      <TableRow>
        <TableCell style={{ fontWeight: 500 }} colSpan={5}>
          {text}
        </TableCell>
      </TableRow>
      {usedAccounts.map((account, i) => (
        <AccountRow
          account={{ nr: account.nr, name: account.name }}
          text={`${account.nr} - ${account.name}`}
          data={account.values}
          key={i}
        />
      ))}
      <AccountRow
        text={sumText}
        //@ts-ignore
        account={{ nr: 0, name: sumText, accounts }}
        data={sum}
      />
    </>
  )
}

export default AccountGroup
