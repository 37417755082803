import { IconButton, makeStyles, Paper } from "@material-ui/core"
// import { blue } from "@material-ui/core/colors"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import React from "react"

export const BudgetMenu = ({
  fullHeight,
  toggleHeight,
}: {
  fullHeight: boolean
  toggleHeight: () => void
}) => {
  const classes = useStyles({ fullHeight })
  return (
    <Paper className={classes.container} elevation={3}>
      <div>
        <IconButton
          className={classes.expand}
          size="small"
          onClick={() => toggleHeight()}
        >
          {fullHeight ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
    </Paper>
  )
}

const useStyles = makeStyles({
  container: {
    position: "fixed",
    height: (props: { fullHeight: boolean }) => (props?.fullHeight ? 128 : 38),
    top: 0,
    left: 80,
    right: 0,
    borderRadius: 0,
    //  backgroundColor: blue[50],
    transition: "height 100ms",
    "& div": {
      position: "relative",
      width: "100%",
      height: "100%",
    },
  },
  expand: {
    position: "absolute",
    right: 4,
    bottom: 4,
  },
})
