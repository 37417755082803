import React from "react"
import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core"

const Page = ({ children, title, maxWidth = "lg" }) => {
  return (
    <Container maxWidth={maxWidth}>
      <Card>
        <CardContent>
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography align="center" variant="h3">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}

export default Page
