import React, { useState } from "react"
import firebase from "../config/firebaseConfig"
import Page from "../company/CompanyRouter/Components/Page"
import {
  TextField,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Paper,
  Slide,
} from "@material-ui/core"
import { cyan } from "@material-ui/core/colors"

const SignIn = () => {
  const [values, setValues] = useState({ email: "", password: "" })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [forgot, setForgot] = useState(false)
  const [forgetEmail, setForgetEmail] = useState("")
  const [sent, setSent] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const { email, password } = values
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch {
      const errorMessage = "Fel mail eller lösenord"
      setError(errorMessage)
    }
  }
  const handleChange = (value, field) => {
    setError("")
    setValues((values) => ({ ...values, [field]: value }))
  }

  const handleForgot = async (e) => {
    e.preventDefault()
    if (forgetEmail !== "") {
      setForgot(false)
      setSent(true)
      setTimeout(() => setSent(false), 4000)
      await firebase.auth().sendPasswordResetEmail(forgetEmail)
    }
  }

  return (
    <>
      <Page title="Logga in" maxWidth="sm">
        {error && (
          <Grid container justify="center">
            <Typography variant="overline" color="error" align="center">
              {error}
            </Typography>
          </Grid>
        )}
        <form onSubmit={handleSubmit}>
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={loading}
                type="email"
                label="Mail"
                required
                variant="outlined"
                value={values.email}
                onChange={(e) => handleChange(e.currentTarget.value, "email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="Lösenord"
                disabled={loading}
                required
                variant="outlined"
                value={values.password}
                onChange={(e) =>
                  handleChange(e.currentTarget.value, "password")
                }
              />
            </Grid>
            <Grid item container xs justify="space-between">
              <Button onClick={() => setForgot(true)}>
                Glömt ditt lösenord?
              </Button>
              <Button type="submit" disabled={loading} variant="contained">
                Logga in
              </Button>
            </Grid>
          </Grid>
        </form>
      </Page>
      <Dialog open={forgot}>
        <DialogTitle>Återställ lösenord</DialogTitle>
        <form onSubmit={handleForgot}>
          <DialogContent>
            <TextField
              type="email"
              fullWidth
              label="Mail"
              onChange={(e) => setForgetEmail(e.currentTarget.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setForgot(false)}>Avbryt</Button>
            <Button color="primary" type="submit">
              Skicka återställningsmail
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Slide direction="down" in={sent} unmountOnExit mountOnEnter>
        <Box
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            left: 80,
          }}
        >
          <Paper style={{ backgroundColor: cyan[50] }}>
            <Typography align="center">{`Ett återställningsmail har skickats till ${forgetEmail}`}</Typography>
          </Paper>
        </Box>
      </Slide>
    </>
  )
}

export default SignIn
