import React from "react"
import "./navbar.scss"
import { Switch, Route } from "react-router-dom"
import { NavItems, CompanyItems } from "./NavItems"
import { cyan } from "@material-ui/core/colors"

const Navbar = () => {
  return (
    <nav
      className="sfb-navbar"
      style={{ zIndex: 1000, backgroundColor: cyan[400] }}
    >
      <ul className="navbar-nav">
        <Switch>
          <Route path="/c/:cid">
            <CompanyItems />
          </Route>
          <Route path="/">
            <NavItems />
          </Route>
        </Switch>
      </ul>
    </nav>
  )
}

export default Navbar
