import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useContext } from "react"
import { Voucher } from "../../../../hooks/Types"
import { thousand } from "../../functions"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { format } from "date-fns"
import { FinancialContext } from "../../Rapporter/FinancialStatement"
import "./styles.css"

const Verifikat = ({
  voucher,
  goBack,
}: {
  voucher: Voucher
  goBack: () => void
}) => {
  //@ts-ignore
  const { accounts } = useContext(FinancialContext)
  const { date, id, text, transactions } = voucher
  const d = new Date(
    parseInt(date.slice(0, 4)),
    parseInt(date.slice(4, 6)) - 1,
    parseInt(date.slice(6, 8))
  )
  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={3}>
            <IconButton size="small" onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
            {`${id} - ${text.slice(1, -1)}`}
          </TableCell>
          <TableCell align="right">{format(d, "yyyy-MM-dd")}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>Konto</TableCell>
          <TableCell align="right">Debet</TableCell>
          <TableCell align="right">Kredit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((transaction, i) => {
          const account = accounts.find((acc) => acc.nr === transaction.nr)
          const { nr, amount } = transaction
          return (
            <TableRow style={{ paddingRight: 4 }} key={i}>
              <TableCell colSpan={2}>{`${nr} - ${account.name}`}</TableCell>
              <TableCell align="right">
                {amount > 0 && thousand(amount)}
              </TableCell>
              <TableCell align="right">
                {amount < 0 && thousand(-amount)}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default Verifikat
