import React from "react"
import { Route, Switch } from "react-router-dom"
import { BudgetHomepage } from "./BudgetHomepage"
import { NewBudget } from "./NewBudget"
import { Worksheet } from "./Worksheet/Worksheet"

export const BudgetPage = () => {
  return (
    <Switch>
      <Route exact path="/c/:cid/budget">
        <BudgetHomepage />
      </Route>
      <Route exact path="/c/:cid/budget/new">
        <NewBudget />
      </Route>
      <Route path="/c/:cid/budget/:budgetId">
        <Worksheet />
      </Route>
    </Switch>
  )
}
