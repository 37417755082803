import React, { createContext, useContext } from "react"
import "./Avvikelse.scss"
import FinancialStatement from "../Rapporter/FinancialStatement"
import { format } from "date-fns"
import { OptionsContext, PeriodContext } from "../CompanyApp"
import { useParams } from "react-router-dom"
import AvvikelseLayout from "./AvvikelseLayout"
import firebase from "../../../config/firebaseConfig"
import { Voucher, VoucherMonth } from "../../../hooks/Types"
import { useCollectionData } from "react-firebase-hooks/firestore"
const db = firebase.firestore()

export const AvvikelseContext = createContext<{
  vouchers: Voucher[]
}>({
  vouchers: [],
})

const Avvikelse = () => {
  const { cid } = useParams() as { cid: string }
  const [period] = useContext(PeriodContext)
  const [options] = useContext(OptionsContext)
  const start = parseInt(format(period.start, "yyyyMM"))
  const end = parseInt(format(period.end, "yyyyMM"))
  const [dbVouchers, loadingVouchers] = useCollectionData<VoucherMonth>(
    db
      .collection(`companies/${cid}/vouchers`)
      .where("month", ">=", start)
      .where("month", "<=", end)
  )

  const vouchers = loadingVouchers
    ? []
    : dbVouchers.reduce((a, c) => [...a, ...c.vouchers], [])

  const jmf = options?.jmf || "bud"
  return (
    <FinancialStatement
      cols={[jmf, "utf", ["avv", "utf", jmf]]}
      title="Avvikelseanalys"
    >
      <AvvikelseContext.Provider value={{ vouchers }}>
        <AvvikelseLayout />
      </AvvikelseContext.Provider>
    </FinancialStatement>
  )
}

export default Avvikelse
