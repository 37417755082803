import React, { useState } from "react"
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Checkbox,
  Button,
} from "@material-ui/core"

export const Jämförelse = ({ setJmf }) => {
  const [checked, setChecked] = useState(["u0"])

  const handleChange = (id: string) => () => {
    setChecked((c) => {
      let newData = checked.includes(id)
        ? c.filter((a) => a !== id)
        : [...c, id]
      setJmf(jmf.filter((n) => newData.includes(n.id)))
      return newData
    })
  }

  return (
    <Grid item xs={6}>
      <Typography>Jämförelse</Typography>
      <Divider style={{ marginBottom: 16 }} />
      <List>
        {jmf.map((j, i) => (
          <ListItem
            key={j.id}
            role={undefined}
            dense
            button
            onClick={handleChange(j.id)}
            divider={i === 2}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                checked={checked.includes(j.id)}
              />
            </ListItemIcon>
            <ListItemText primary={j.text} />
          </ListItem>
        ))}
      </List>
    </Grid>
  )
}

const jmf: IJmf[] = [
  {
    id: "u0",
    text: "Utfall",
  },
  {
    id: "u1",
    text: "Föregående år",
  },
  {
    id: "b",
    text: "Budget",
  },
  {
    id: "u0Acc",
    text: "Ackumulerat utfall",
  },
  {
    id: "u1Acc",
    text: "Ackumulerat utfall föregående år",
  },
  {
    id: "bAcc",
    text: "Ackumulerad budget",
  },
]

export const defaultJmf = [jmf[0]]

export interface IJmf {
  text: string
  id: string
}
