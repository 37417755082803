import { Access, User } from "./Types"
import firebase from "../config/firebaseConfig"
import { useEffect, useState } from "react"
import { useRouteMatch } from "react-router-dom"
const db = firebase.firestore()

export const useUserData = () => {
  const [uid, setUid] = useState<null | string>(null)
  const [data, setData] = useState<User | null>(null)
  const [loading, setLoading] = useState(false)

  const getData = async (uid: string) => {
    setLoading(true)
    const userData = (
      await db.collection(`users`).doc(uid).get()
    ).data() as User
    setData(userData)
    setLoading(false)
  }

  useEffect(() => {
    if (uid) {
      getData(uid)
    }
  }, [uid])

  firebase.auth().onAuthStateChanged((user) => {
    if (user?.uid !== uid) {
      if (user) {
        setUid(user.uid)
      } else {
        setUid(null)
        setData(null)
      }
    }
  })
  return [data, loading]
}

export const useDB = () => {}

export const useCompany = () => {
  // const [state, dispatch] = useContext(CompanyContext)
  const match = useRouteMatch("/c/:cid")
  const [cid, setCid] = useState<string | null>(match.params?.["cid"])
  const [userAccess, setUserAccess] = useState<Access | null>()

  useEffect(() => {
    if (cid !== match.params?.["cid"]) setCid(match.params?.["cid"])
  }, [match, cid])

  useEffect(() => {
    const uid = firebase.auth().currentUser.uid
    const unsubscribe = db
      .doc(`companies/${cid}/access/${uid}`)
      .onSnapshot((doc) => {
        const access = doc.data() as Access
        setUserAccess(access)
      })
    return unsubscribe
  }, [cid])

  if (!cid) return null
  return userAccess
}

export const useFunction = (functionName: string) => {
  const functions = firebase.functions()
  const func = functions.httpsCallable(functionName)
  return func
}

export const createMonthArray = (
  currentMonth: number,
  endMonth: number,
  months: number = 20
): number[] => {
  if (endMonth < currentMonth || months <= 0) return []
  const nextMonth =
    currentMonth.toString().slice(4, 6) === "12"
      ? currentMonth + 89
      : currentMonth + 1
  return [currentMonth, ...createMonthArray(nextMonth, endMonth, months - 1)]
}

export const useLogo = (cid: string) => {
  const [url, setUrl] = useState<string | null>(null)
  const [done, setDone] = useState<boolean>(false)
  const [companyId, setCompanyId] = useState<string>(cid)
  const logoRef = firebase.storage().ref(`${cid}/logo.ico`)
  useEffect(() => {
    const getLogo = async () => {
      setDone(true)
      try {
        const url = await logoRef.getDownloadURL()
        setUrl(url)
        setCompanyId(cid)
      } catch {}
    }
    if (!done) getLogo()
    else if (companyId !== cid) getLogo()
  })
  return url
}
