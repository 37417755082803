import React, { useContext } from "react"
import { useState } from "react"
import { useEffect } from "react"
import Page from "../company/CompanyRouter/Components/Page"
import { Grid, Typography, TextField, Button } from "@material-ui/core"
import { UserContext } from "../App.tsx"
import firebase from "../config/firebaseConfig"

const UserProfile = () => {
  const [values, setValues] = useState(null)
  const [loading, setLoading] = useState(false)
  const user = useContext(UserContext)

  useEffect(() => {
    if (user && !values) {
      const { email, firstName, lastName } = user
      setValues({
        email,
        firstName,
        lastName,
      })
    }
  }, [user, values])

  const handleChange = (e) => {
    const field = e.target.id
    const value = e.target.value
    setValues((values) => ({ ...values, [field]: value }))
  }

  if (!values) return null
  const displayName = `${user.firstName} ${user.lastName}`

  const handleSave = async (e) => {
    e.preventDefault()
    setLoading(true)
    const user = firebase.auth().currentUser
    const { email, firstName, lastName } = values
    const displayName = `${firstName} ${lastName}`
    const sameName = displayName !== `${user.firstName} ${user.lastName}`
    const sameEmail = email !== user.email
    if (!sameName) {
      await user.updateProfile({ displayName })
    }
    if (!sameEmail) {
      await user.updateEmail(email)
    }
    if (!sameName || !sameEmail) {
      await firebase.functions().httpsCallable("updateProfile")({
        email,
        displayName,
        firstName,
        lastName,
      })
    }
    setLoading(false)
  }
  return (
    <Page title={displayName}>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={3}>
          <form onSubmit={handleSave}>
            <Grid container spacing={3} item xs={8}>
              <Grid item xs={12}>
                <Typography variant="h5">Redigera användare</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={loading}
                  value={values?.email}
                  onChange={handleChange}
                  id="email"
                  label="Mail"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={loading}
                  value={values?.firstName}
                  onChange={handleChange}
                  id="firstName"
                  label="Förnamn"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={loading}
                  value={values?.lastName}
                  onChange={handleChange}
                  id="lastName"
                  label="Efternamn"
                  variant="outlined"
                />
              </Grid>
              <Grid item container xs justify="flex-end">
                <Button
                  type="submit"
                  disabled={loading}
                  size={"large"}
                  variant="contained"
                >
                  Spara
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Page>
    // <div className="container">
    //   <div className="card">
    //     <div className="card-content">
    //       <h3>{displayName}</h3>
    //       <div className="divider"></div>
    //       <div className="settings row">
    //         <p
    //           onClick={() => setShowEdit((showEdit) => !showEdit)}
    //           className="edit"
    //         >
    //           Redigera användare{" "}
    //           <i className="material-icons">keyboard_arrow_down</i>
    //         </p>
    //         {showEdit && (
    //           <div>
    //             <br />
    //             <div className="input-field">
    //               <input
    //                 id="email"
    //                 type="email"
    //                 value={fields.email}
    //                 onChange={(e) =>
    //                   handleFields("email", e.currentTarget.value)
    //                 }
    //               />
    //               <label className="active" htmlFor="email">
    //                 Mail
    //               </label>
    //             </div>
    //             <div className="input-field">
    //               <input
    //                 id="name"
    //                 type="text"
    //                 value={fields.displayName}
    //                 onChange={(e) =>
    //                   handleFields("displayName", e.currentTarget.value)
    //                 }
    //               />
    //               <label className="active" htmlFor="name">
    //                 Namn
    //               </label>
    //             </div>
    //             <button onClick={handleSave} className="btn">
    //               Spara
    //             </button>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default UserProfile
