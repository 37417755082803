import {
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import AddIcon from "@material-ui/icons/Add"
import React, { useContext, useState } from "react"
import { CompanyContext } from ".."
import { Access } from "../../../hooks/Types"
import { format } from "date-fns"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { useFunction } from "../../../hooks/customHooks"
import { Redirect, useParams } from "react-router-dom"

export const NewBudget = () => {
  const defaultBudgetInfo = useDefaultBudgetInfo()
  const [loading, setLoading] = useState(false)
  const [budgetId, setBudgetId] = useState<string>(null)
  const createBudget = useFunction("createBudget")
  const [advanced, setAdvanced] = useState<Advanced>({
    kontoplan: "default",
    base: "default",
    ks: "",
    pr: "",
    ccBudget: false,
  })
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [name, setName] = useState(defaultBudgetInfo.name)
  const [start, setStart] = useState(defaultBudgetInfo.start)
  const [end, setEnd] = useState(defaultBudgetInfo.end)
  const { cid } = useParams() as { cid: string }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    // console.log("yay", { name, start, end, advanced })
    setLoading(true)
    console.log({ start, end })
    const data: CreateBudgetData = {
      cid,
      name,
      from: parseInt(format(start, "yyyyMM")),
      to: parseInt(format(end, "yyyyMM")),
      cc: advanced.ks,
      pr: advanced.pr,
      defaultKontoplan: advanced.kontoplan,
    }
    const res = await createBudget(data)
    console.log({ res })
    setBudgetId(res.data)
    setLoading(false)
  }
  if (budgetId) {
    return <Redirect to={`/c/${cid}/budget/${budgetId}`} />
  }
  return (
    <Container>
      <Typography style={{ marginBottom: 32 }} variant="h3" align="center">
        Ny budget
      </Typography>
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      disabled={loading}
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.currentTarget.value)}
                      variant="outlined"
                      label="Budgetnamn"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      disabled={loading}
                      inputVariant="outlined"
                      views={["year", "month"]}
                      value={start}
                      onChange={(date) => setStart(date)}
                      openTo="month"
                      format="yyyy-MM"
                      label="Från"
                      variant="inline"
                      autoOk
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      disabled={loading}
                      inputVariant="outlined"
                      views={["year", "month"]}
                      value={end}
                      onChange={(date) => setEnd(date)}
                      openTo="month"
                      format="yyyy-MM"
                      label="Till"
                      variant="inline"
                      autoOk
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    style={{ cursor: "pointer" }}
                  >
                    {showAdvanced ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <Typography>Avancerat</Typography>
                  </Grid>
                </Grid>
                <Collapse
                  style={{ width: "100%" }}
                  mountOnEnter
                  in={showAdvanced}
                >
                  <AdvancedBudgetSettings
                    loading={loading}
                    advanced={advanced}
                    setAdvanced={setAdvanced}
                  />
                </Collapse>
                <Grid container spacing={2}>
                  <Grid item container xs={12} justify="flex-end">
                    <Button
                      disabled={loading}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Skapa budget
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

interface Advanced {
  kontoplan: string
  base: any
  ks: string
  pr: string
  ccBudget: boolean
}

const AdvancedBudgetSettings = ({
  loading,
  advanced,
  setAdvanced,
}: {
  loading: boolean
  advanced: Advanced
  setAdvanced: (arg: any) => void
}) => {
  const company = useContext(CompanyContext) as Access
  console.log({ company })
  const { kontoplan, base, ks, pr } = advanced
  const costCenter = [{ nr: "", name: "Inget" }, ...(company?.cc || [])]
  const projects = [{ nr: "", name: "Inget" }, ...(company?.pr || [])]

  const handleChange = (type: string) => (e, { props }) => {
    const { value } = props
    setAdvanced((adv) => ({ ...adv, [type]: value }))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item xs>
        <FormControl disabled={loading} fullWidth variant="outlined">
          <InputLabel id="kontoplan-label">Kontoplan</InputLabel>
          <Select
            fullWidth
            labelId="kontoplan-label"
            label="Kontoplan"
            value={kontoplan}
            onChange={handleChange("kontoplan")}
          >
            <MenuItem value="default">Standard</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <IconButton disabled={loading}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormControl disabled={loading} fullWidth variant="outlined">
          <InputLabel id="base-label">Basvärden</InputLabel>
          <Select
            fullWidth
            labelId="base-label"
            label="Basvärden"
            value={base}
            onChange={handleChange("base")}
          >
            <MenuItem value="default">Utfall senaste 12mån</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl disabled={loading} fullWidth variant="outlined">
          <InputLabel id="ks-label">Kostnadsställe</InputLabel>
          <Select
            fullWidth
            labelId="ks-label"
            label="Kostnadsställe"
            value={ks}
            onChange={handleChange("ks")}
          >
            {costCenter.map((cc) => (
              <MenuItem key={cc.nr} value={cc.nr}>
                {cc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl disabled={loading} fullWidth variant="outlined">
          <InputLabel id="pr-label">Projekt</InputLabel>
          <Select
            fullWidth
            labelId="pr-label"
            label="Projekt"
            value={pr}
            onChange={handleChange("pr")}
          >
            {projects.map((pr) => (
              <MenuItem key={pr.nr} value={pr.nr}>
                {pr.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  )
}

const useDefaultBudgetInfo = () => {
  const company = useContext(CompanyContext) as Access
  const financialyears = company.financialyears
  const avstämd = company?.avstämd
  const currentYear = Boolean(avstämd)
    ? financialyears.find(
        (y) =>
          parseInt(y.from.slice(0, 6)) <= avstämd &&
          parseInt(y.to.slice(0, 6)) >= avstämd
      )
    : financialyears[0]
  const { from, to } = currentYear

  const start = toDate((parseInt(from) + 10000).toString())
  const end = toDate((parseInt(to) + 10000).toString())
  const name = `Budget ${yearText(start, end)}`

  return { name, start, end }
}
const toDate = (date: string) =>
  new Date(
    parseInt(date.slice(0, 4)),
    parseInt(date.slice(4, 6)) - 1,
    date.length === 8 ? parseInt(date.slice(6, 8)) : 1
  )
const yearText = (from: Date, to: Date) => {
  const fromY = format(from, "yyyy")
  const toY = format(to, "yyyy")
  return fromY === toY ? fromY : `${fromY} - ${toY}`
}

interface CreateBudgetData {
  cid: string
  name: string
  from: number
  to: number
  cc?: string
  pr?: string
  defaultKontoplan: string
}
