import {
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import AddIcon from "@material-ui/icons/Add"
import React, { useContext } from "react"
import { CompanyContext } from "../../../App"
import { Access } from "../../../hooks/Types"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useParams } from "react-router-dom"
import firebase from "../../../config/firebaseConfig"
import { Budget } from "../../../Types/Database"
const db = firebase.firestore()

export const BudgetHomepage = () => {
  const accessData = useContext(CompanyContext) as Access
  const { cid } = useParams() as { cid: string }
  const budgetQuery = db.collection(`companies/${cid}/budgets`)

  //   const [budgets, loading] = useCollectionData<Budget>(budgetQuery)
  const [allBudgets, loading] = useCollectionData<Budget>(budgetQuery)
  //@ts-ignore
  const budgets = allBudgets?.filter((b) => !b?.old) || allBudgets

  const allowCreateBudget = accessData?.role === "konsult"

  return (
    <Container>
      <Typography style={{ marginBottom: 32 }} variant="h3" align="center">
        Budget
      </Typography>
      <Grid container justify="center">
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <List>
                {allowCreateBudget && (
                  <Link href={`/c/${cid}/budget/new`} color="inherit">
                    <ListItem button divider>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Skapa ny budget" />
                    </ListItem>
                  </Link>
                )}
                {loading ? (
                  <LinearProgress />
                ) : (
                  budgets.map((budget) => {
                    return (
                      <Link
                        href={`/c/${cid}/budget/${budget.id}`}
                        color="inherit"
                      >
                        <ListItem button divider>
                          <ListItemText
                            primary={budget.name}
                            secondary={budget.from}
                          />
                          <ListItemSecondaryAction>
                            <IconButton>
                              <EditIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Link>
                    )
                  })
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
