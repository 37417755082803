import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Collapse, IconButton, TableCell, TableRow } from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import AccountData from "./AccountData"
import { OptionsContext } from "../../CompanyApp"
import { thousand } from "../../functions"

interface Trans {
  index: number
  voucher: {
    id: string
    text: string
    date: string
  }
  amount: number
  pr: number | boolean
  cc: number | boolean
  desc?: string
  nr: number
}

export const AccountContext = createContext<{
  account: { nr: number; name: string; accounts?: [number, number] }
  noteState?: [Trans[], React.Dispatch<React.SetStateAction<Trans[]>>]
}>({ account: { nr: null, name: null } })

const AccountRow = ({
  text,
  account,
  data,
  classes = "",
}: {
  text: string
  account: { nr: number; name: string; accounts?: [number, number] }
  data: number[]
  classes?: string
}) => {
  const [state] = useContext(OptionsContext)
  const noteState = useState<Trans[]>([])
  const ref = useRef()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (ref.current) {
      const { nr, name } = account
      state.addRef(ref.current, nr || name, data[2])
    }
  }, [account, data, state])
  return (
    <>
      <TableRow hover selected={open} className={classes} ref={ref}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes}>{text}</TableCell>
        <TableCell align="right">{thousand(-data[0])}</TableCell>
        <TableCell align="right">{thousand(-data[1])}</TableCell>
        <TableCell align="right">{thousand(-data[2])}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Collapse in={open} mountOnEnter unmountOnExit>
            <div>
              <AccountContext.Provider value={{ account, noteState }}>
                <AccountData />
              </AccountContext.Provider>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default AccountRow
