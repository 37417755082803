import React, { useState, useEffect, useContext } from "react"
import CompanyCard from "./CompanyCard"
import NoCompanies from "./NoCompanies"
import { List } from "@material-ui/core"
import { UserContext } from "../App.tsx"
import firebase from "../config/firebaseConfig"
const db = firebase.firestore()

const CompanyCards = ({ search = "" }) => {
  const [companies, setCompanies] = useState([])
  const [searchedCompanies, setSearchedCompanies] = useState([])
  const user = useContext(UserContext)
  const uid = user?.uid

  // useSelector((state) => state.firestore.ordered["companies"]) || []
  useEffect(() => {
    if (!Boolean(uid) || companies.length) return
    const getLogo = async (company) => {
      const { id, logoFileName } = company
      try {
        const logoURL = await firebase
          .storage()
          .ref(`${id}/${logoFileName}`)
          .getDownloadURL()
        return logoURL
      } catch {
        return ""
      }
    }
    const mapCompanies = async (allCompanies) => {
      const companies = allCompanies.map(async (company) => {
        const logo = await getLogo(company)
        return { ...company, logo }
      })

      setCompanies(await Promise.all(companies))
    }
    const unsubscribe = db
      .collectionGroup("access")
      .where("uid", "==", uid)
      .onSnapshot((snapshot) => {
        const allCompanies = snapshot.docs.map((doc) => doc.data())
        mapCompanies(allCompanies)
      })

    return unsubscribe
  }, [companies, uid])

  useEffect(() => {
    const regex = new RegExp(search, "i")
    const searched = companies.filter((company) => {
      return (
        company.name.search(regex) !== -1 || company.orgnr.search(regex) !== -1
      )
    })
    setSearchedCompanies(searched)
  }, [search, companies])

  return (
    <div>
      {searchedCompanies.length !== 0 ? (
        <List>
          {searchedCompanies.map((company, i) => (
            <CompanyCard key={i} company={company} />
          ))}
        </List>
      ) : (
        <NoCompanies />
      )}
    </div>
  )
}

export default CompanyCards
