import React from "react"
import { Link } from "react-router-dom"
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"

const CompanyCard = ({ company }) => {
  const { name, orgnr, cid, logo } = company
  return (
    <Link
      to={`/c/${cid}`}
      target="_blank"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button divider>
        {logo && (
          <ListItemAvatar>
            <Avatar src={logo} />
          </ListItemAvatar>
        )}
        <ListItemText primary={name} secondary={orgnr} inset={!logo} />
      </ListItem>
    </Link>
  )
}

export default CompanyCard
