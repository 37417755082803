import { Divider, Grid, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { thousand } from "../functions"
import { FinancialContext } from "./FinancialStatement"

export const Sum = ({
  accounts,
  text,
  size = false,
}: {
  accounts: [number, number]
  text: string
  size?: Size
}) => {
  // const { accounts: allAccounts } = useContext(FinancialContext)

  const { data } = useContext(FinancialContext)
  const [from, to] = accounts

  const usedAccounts = data.accounts.filter(
    (account) => account.nr >= from && account.nr <= to
  )

  const sum = usedAccounts.reduce(
    (acc, curr) => acc.map((a, i) => a + (curr.values?.[i] || 0)),
    [0, 0, 0]
  )

  return (
    <div style={{ marginTop: 8 }}>
      <Row data={sum} text={text} bold size={size} />
    </div>
  )
}

export type Column =
  | "utf"
  | "bud"
  | "ib"
  | "diff"
  | "ub"
  | "fgå"
  | "fgm"
  | ["avv", Column, Column]

export const AccountGroup = ({
  accounts,
  text,
  hideAccounts = false,
}: {
  accounts: [number, number]
  text: string
  hideAccounts?: boolean
}) => {
  // const { accounts: allAccounts } = useContext(FinancialContext)
  const { data } = useContext(FinancialContext)
  const [from, to] = accounts

  const usedAccounts = data.accounts.filter(
    (account) => account.nr >= from && account.nr <= to
  )

  const sum = usedAccounts.reduce(
    (acc, curr) => acc.map((a, i) => a + (curr.values?.[i] || 0)),
    [0, 0, 0]
  )
  if (usedAccounts.every((acc) => acc.values.every((v) => v === 0))) return null
  return (
    <div style={{ marginTop: 16 }}>
      <Typography style={{ textDecoration: "underline", fontSize: 22 }}>
        {text}
      </Typography>
      <div style={{ paddingLeft: 8 }}>
        {usedAccounts.map((account, i) => {
          const { nr, name, values } = account
          return (
            <Row
              key={i}
              text={`${nr} - ${name}`}
              data={values}
              odd={i % 2 === 1}
            />
          )
        })}
      </div>
      <Divider />
      <Row data={sum} text={`Summa ${text.toLowerCase()}`} bold />
    </div>
  )
}

export const Header = ({
  text,
}: {
  text: [string, string, string, string]
}) => {
  const fontSize = 24
  return (
    <Grid item container>
      <Grid item xs={6}>
        <Typography noWrap style={{ fontSize }}>
          {text[0]}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ textAlign: "right", fontSize }}>
          {text[1]}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ textAlign: "right", fontSize }}>
          {text[2]}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ textAlign: "right", fontSize }}>
          {text[3]}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const Row = ({
  data,
  text,
  bold = false,
  size = false,
  odd,
}: {
  data: number[]
  text: string
  bold?: boolean
  size?: Size
  odd?: boolean
}) => {
  const [b1, b2, b3] = data
  const fontSize = 20
  return (
    <Grid
      item
      container
      style={{
        ...(odd && { backgroundColor: "rgba(0,0,0,0.05)" }),
        ...(bold && { fontWeight: 500 }),
        ...(size && { fontSize: size }),
        padding: 2,
      }}
    >
      <Grid item xs={6}>
        <Typography noWrap style={{ fontSize }}>
          {text}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ textAlign: "right", fontSize }}>
          {thousand(-b1)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ textAlign: "right", fontSize }}>
          {thousand(-b2)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ textAlign: "right", fontSize }}>
          {thousand(-b3)}
        </Typography>
      </Grid>
    </Grid>
  )
}

type Size = string | number | (string & {}) | undefined | false
