import React, { useState } from "react"
import CompanyCards from "./CompanyCards"
import "./index.css"
import { Grid, TextField, InputAdornment } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import Page from "../company/CompanyRouter/Components/Page"

const CompanyMenu = () => {
  const [search, setSearch] = useState("")
  const handleSearch = (e) => setSearch(e.target.value)

  return (
    <Page title="Företag">
      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={handleSearch}
          label="Sök företag"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CompanyCards search={search} />
      </Grid>
    </Page>
  )
}

export default CompanyMenu
