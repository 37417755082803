import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  TableContainer,
} from "@material-ui/core"
import React, { useContext, useState } from "react"
import { BudgetMenu } from "./BudgetMenu"
import { CellStoreContext } from "./Context/CellStore"

export const BudgetTable = ({ children }: { children: React.ReactNode }) => {
  console.log("Rendering BudgetTable")
  const { header = [] } = useContext(CellStoreContext) || {}
  const [menuFullHeight, setMenuFullHeight] = useState(false)
  const classes = useStyles({ fullHeight: menuFullHeight })
  const toggleHeight = () => setMenuFullHeight((h) => !h)
  return (
    <>
      <BudgetMenu fullHeight={menuFullHeight} toggleHeight={toggleHeight} />
      <div className={classes.contain}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                {header.map((text, i) => {
                  let colSpan = 1
                  // if (i === 0) colSpan = 2
                  return (
                    <TableCell colSpan={colSpan} align="right" key={text}>
                      {text}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <AddAccount isOpen={open} close={() => setOpen(false)} addAccount={addAccount} /> */}
    </>
  )
}

const useStyles = makeStyles({
  tableContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    overflowY: "scroll",
    marginTop: 16,
  },
  contain: {
    position: "fixed",
    overflowX: "visible",
    transition: "top 100ms",
    top: (props: { fullHeight: boolean }) => (props?.fullHeight ? 128 : 38),
    right: 4,
    left: 84,
    bottom: 0,
    paddingTop: 8,
  },
  table: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
  },
  row: {
    padding: 0,
  },
  cell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})
