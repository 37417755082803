import { Button, Divider, Grid, Typography } from "@material-ui/core"
import React, { useContext, useState } from "react"
import { CompanyContext } from ".."
import Page from "../Components/Page"
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers"
import { format } from "date-fns"
import { useFunction } from "../../../hooks/customHooks"
import { INyckeltal, Nyckeltal } from "./Nyckeltal"
import { defaultJmf, IJmf, Jämförelse } from "./Jämförelse"
import XLSX from "xlsx"
import { Mallar } from "./Mallar"
import { Access } from "../../../hooks/Types"

export const Report = () => {
  const company = useContext<Access>(CompanyContext)

  const getNyckeltal = useFunction("getNyckeltal")

  const [start, setStart] = useState(new Date())
  const [end, setEnd] = useState(new Date())
  const [nyckeltal, setNyckeltal] = useState<INyckeltal[]>([])
  const [jmf, setJmf] = useState<IJmf[]>(defaultJmf)

  const setMall = () => {}

  const exportReport = async () => {
    //Hämta db data och presentera på slide

    const { cid } = company as { cid: string }
    const from = parseInt(format(start, "yyyyMM"))
    const to = parseInt(format(end, "yyyyMM"))

    const data = (await getNyckeltal({
      cid,
      from,
      to,
      jmf: jmf.map((j) => j.id),
    })) as IBalancesData

    const ACK = ["u0Acc", "u1Acc", "bAcc"]

    const getNumber = (
      jmf: string,
      fromAcc: number,
      toAcc: number,
      fromDate: number,
      toDate: number
    ): number => {
      return data.data[jmf].reduce((acc, curr) => {
        if (
          ((jmf === "u0Acc" || jmf === "bAcc") && curr.month <= toDate) ||
          (jmf === "u1Acc" && curr.month <= toDate - 100)
        ) {
          if (curr.account >= fromAcc && curr.account <= toAcc) {
            if (!curr.pr && !curr.cc) return acc + curr.amount
            return acc
          }
          return acc
        } else if (curr.month >= fromDate && curr.month <= toDate) {
          if (curr.account >= fromAcc && curr.account <= toAcc) {
            if (!curr.pr && !curr.cc) return acc + curr.amount
          }
        }
        return acc
      }, 0)
    }
    console.log({ data })

    let wb = XLSX.utils.book_new()
    let ws = XLSX.utils.aoa_to_sheet([
      ["Nyckeltal", ...jmf.map((a) => a.text)],
      ...nyckeltal.map((s) => {
        return [
          s.text,
          ...jmf.map((j) => {
            const data = getNumber(
              j.id,
              s.kontoFrom,
              s.kontoTo,
              from - (j.id === "u1" ? 100 : 0),
              to - (j.id === "u1" ? 100 : 0)
            )
            return -Math.round(data)
          }),
        ]
      }),
    ])

    XLSX.utils.book_append_sheet(wb, ws, "Nyckeltal")

    XLSX.writeFile(wb, `${company.name} ${from}-${to}.xlsx`)
  }
  const datePickerSettings: Partial<KeyboardDatePickerProps> = {
    views: ["year", "month"],
    openTo: "year",
    format: "yyyy-MM",
    variant: "inline",
    autoOk: true,
  }

  return (
    <>
      <Page title="Nyckeltalsrapport">
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography>Period</Typography>
            <Divider style={{ marginBottom: 16 }} />
            <KeyboardDatePicker
              {...datePickerSettings}
              value={start}
              onChange={(date) => setStart(date)}
              label="Från"
            />
            <KeyboardDatePicker
              {...datePickerSettings}
              value={end}
              onChange={(date) => setEnd(date)}
              label="Till"
              style={{
                marginLeft: 16,
              }}
            />
          </Grid>
          {/* <Mallar setMall={null} /> */}
          <Nyckeltal
            setNyckeltal={(data: INyckeltal[]) => setNyckeltal(data)}
          />
          <Jämförelse setJmf={(data) => setJmf(data)} />
          <Grid item xs={12}>
            <Button onClick={exportReport}>Exportera</Button>
          </Grid>
        </Grid>
      </Page>
    </>
  )
}

interface IBalancesData {
  data: [
    {
      balances: IBalances[]
      budget: IBalances[]
    }
  ]
}

interface IBalances {
  month: number
  account: number
  amount: number
  pr?: string
  cc?: string
}
