import React, { useContext } from "react"
import FinancialStatement from "./FinancialStatement"
import { Divider } from "@material-ui/core"
import { AccountGroup, Column, Header, Sum } from "./LayoutModules"
import { OptionsContext } from "../CompanyApp"

export const Resultatrapport = () => {
  const [state] = useContext(OptionsContext)
  const jmfCol = state?.jmf || ("bud" as Column)
  return (
    <FinancialStatement
      cols={[jmfCol, "utf", ["avv", "utf", jmfCol]]}
      title="Resultatrapport"
    >
      <>
        <Header text={["Konto", colTitle(jmfCol), "Utfall", "Avvikelse"]} />
        <Divider />
        <AccountGroup accounts={[3000, 3999]} text="Omsättning" />
        <AccountGroup
          accounts={[4000, 4999]}
          text="Råvaror och förnödenheter"
        />
        <Sum accounts={[3000, 4999]} text="Bruttovinst" size="1.2rem" />
        <AccountGroup accounts={[5000, 6999]} text="Övriga externa kostnader" />
        <AccountGroup accounts={[7000, 7699]} text="Personalkostnader" />
        <Sum accounts={[3000, 7699]} text="EBITDA" size="1.2rem" />
        <AccountGroup accounts={[7700, 7899]} text="Avskrivningar" />
        <AccountGroup accounts={[7900, 7999]} text="Övriga rörelsekostnader" />
        <AccountGroup accounts={[8000, 8499]} text="Finansiella poster" />
        <Sum accounts={[3000, 8989]} text="Resultat" size="1.3rem" />
      </>
    </FinancialStatement>
  )
}

export const ResultatrapportMin = () => {
  const [state] = useContext(OptionsContext)
  const jmfCol = state?.jmf || ("bud" as Column)

  return (
    <FinancialStatement
      cols={[jmfCol, "utf", ["avv", "utf", jmfCol]]}
      title="Resultatrapport"
    >
      <>
        <Header text={["Konto", colTitle(jmfCol), "Utfall", "Avvikelse"]} />
        <Divider />
        <Sum accounts={[3000, 3999]} text="Omsättning" />
        <Sum accounts={[4000, 4999]} text="Råvaror och förnödenheter" />
        <Divider />
        <Sum accounts={[3000, 4999]} text="Bruttovinst" />
        <Sum accounts={[5000, 6999]} text="Övriga externa kostnader" />
        <Sum accounts={[7000, 7699]} text="Personalkostnader" />
        <Divider />
        <Sum accounts={[3000, 7699]} text="EBITDA" />
        <Sum accounts={[7700, 7899]} text="Avskrivningar" />
        <Sum accounts={[7900, 7999]} text="Övriga rörelsekostnader" />
        <Sum accounts={[8000, 8499]} text="Finansiella poster" />
        <Divider />
        <Sum accounts={[3000, 8989]} text="Resultat" size="1.3rem" />
      </>
    </FinancialStatement>
  )
}

export const Balansrapport = () => {
  return (
    <FinancialStatement cols={["ib", "diff", "ub"]} title="Balansrapport">
      <>
        <Header
          text={["Konto", "Ingående balans", "Period", "Utgående balans"]}
        />
        <Divider />
        <AccountGroup
          accounts={[1000, 1099]}
          text="Immateriella anläggningstillgångar"
        />
        <AccountGroup
          accounts={[1100, 1299]}
          text="Materiella anläggningstillgångar"
        />
        <AccountGroup
          accounts={[1300, 1399]}
          text="Finansiella anläggningstillgångar"
        />
        <Sum accounts={[1000, 1399]} text="Summa anläggningstillgångar" />
        <AccountGroup accounts={[1400, 1499]} text="Varulager m.m." />
        <AccountGroup accounts={[1500, 1799]} text="Kortfristiga fordringar" />
        <AccountGroup accounts={[1800, 1899]} text="Kortfristiga placeringar" />
        <AccountGroup accounts={[1900, 1999]} text="Kassa och bank" />
        <Sum accounts={[1400, 1999]} text="Summa omsättningstillgångar" />
        <Sum accounts={[1000, 1999]} text="Summa tillgångar" size="1.4rem" />
        <AccountGroup accounts={[2000, 2099]} text="Eget kapital" />
        <AccountGroup accounts={[2100, 2199]} text="Obeskattade reserver" />
        <AccountGroup accounts={[2200, 2299]} text="Avsättningar" />
        <AccountGroup accounts={[2300, 2399]} text="Långfristiga skulder" />
        <AccountGroup accounts={[2400, 2999]} text="Kortfristiga skulder" />
        <Sum
          accounts={[2000, 2999]}
          text="Summa eget kapital, avsättningar och skulder"
        />
        <Sum accounts={[1000, 2999]} text="Beräknat resultat" size="1.4rem" />
      </>
    </FinancialStatement>
  )
}

export const BalansrapportMin = () => {
  return (
    <FinancialStatement cols={["ib", "diff", "ub"]} title="Balansrapport">
      <>
        <Header
          text={["Konto", "Ingående balans", "Period", "Utgående balans"]}
        />
        <Divider />
        <Sum
          accounts={[1000, 1099]}
          text="Immateriella anläggningstillgångar"
        />
        <Sum accounts={[1100, 1299]} text="Materiella anläggningstillgångar" />
        <Sum accounts={[1300, 1399]} text="Finansiella anläggningstillgångar" />
        <Sum accounts={[1000, 1399]} text="Summa anläggningstillgångar" />
        <Sum accounts={[1400, 1499]} text="Varulager m.m." />
        <Sum accounts={[1500, 1799]} text="Kortfristiga fordringar" />
        <Sum accounts={[1800, 1899]} text="Kortfristiga placeringar" />
        <Sum accounts={[1900, 1999]} text="Kassa och bank" />
        <Sum accounts={[1400, 1999]} text="Summa omsättningstillgångar" />
        <Sum accounts={[1000, 1999]} text="Summa tillgångar" size="1.4rem" />
        <Sum accounts={[2000, 2099]} text="Eget kapital" />
        <Sum accounts={[2100, 2199]} text="Obeskattade reserver" />
        <Sum accounts={[2200, 2299]} text="Avsättningar" />
        <Sum accounts={[2300, 2399]} text="Långfristiga skulder" />
        <Sum accounts={[2400, 2999]} text="Kortfristiga skulder" />
        <Sum
          accounts={[2000, 2999]}
          text="Summa eget kapital, avsättningar och skulder"
        />
        <Sum accounts={[1000, 2999]} text="Beräknat resultat" size="1.4rem" />
      </>
    </FinancialStatement>
  )
}

const colTitle = (col: Column) => {
  switch (col) {
    case "bud":
      return "Budget"
    case "fgå":
      return "Föregående år"

    default:
      return "Budget"
  }
}
