import React, { useContext, useEffect, useRef, useState } from "react"
import "./menu.scss"
import {
  makeStyles,
  Drawer,
  Divider,
  List,
  ListItem,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { cyan } from "@material-ui/core/colors"
import { OptionsContext, PeriodContext } from "../CompanyApp"
import { useFunction } from "../../../hooks/customHooks.ts"
import { CompanyContext } from "../../../App.tsx"
import { Route, useParams } from "react-router-dom"
import NumberFormat from "react-number-format"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { format } from "date-fns"
import firebase from "../../../config/firebaseConfig"
const db = firebase.firestore()

const Menu = () => {
  const classes = useStyles()
  const company = useContext(CompanyContext)
  const financialyears = company.financialyears

  const konsult = company?.role === "konsult"
  const avstämd = Boolean(company?.avstämd)

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <div className={classes.toolbar} />
        <Divider />
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ height: "100%" }}
        >
          {(konsult || avstämd) && (
            <Grid container item direction="column">
              <PeriodSelect
                financialyears={financialyears}
                avstämd={company.avstämd}
              />
              <Divider />
              <Route path="/c/:cid/rr">
                <FinancialStatementOptions />
              </Route>

              <Route path="/c/:cid/br">
                <FinancialStatementOptions />
              </Route>
              <Route path="/c/:cid/avvikelse">
                <AvvikelseOptions />
              </Route>
            </Grid>
          )}
          {konsult && <GetData financialyears={financialyears} />}
        </Grid>
      </Drawer>
    </div>
  )
}

export default Menu

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: cyan[400],
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

const PeriodSelect = ({ avstämd, financialyears }) => {
  // const company = useContext(CompanyContext)
  const [period, setPeriod] = useContext(PeriodContext)
  const { start: startDate, end: endDate, max, min } = period

  const [start, setStart] = useState(startDate)
  const [end, setEnd] = useState(endDate)

  const handlePeriod = () => {
    setPeriod((period) => ({ ...period, start, end }))
  }

  const nextMonth = (month) => {
    const m = month.toString().slice(4, 6)
    return parseInt(month) + (m === "12" ? 89 : 1)
  }

  const handleShortPeriod = (type) => {
    const avstämdDate = new Date(
      parseInt(avstämd.toString().slice(0, 4)),
      parseInt(avstämd.toString().slice(4, 6)) - 1,
      1
    )
    if (type === "acc") {
      const currentYear = financialyears.find(
        (year) =>
          parseInt(year.from.slice(0, 6)) <= avstämd &&
          parseInt(year.to.slice(0, 6)) >= avstämd
      )
      const from = currentYear.from
      const start = new Date(
        parseInt(from.slice(0, 4)),
        parseInt(from.slice(4, 6)) - 1,
        1
      )
      setStart(start)
      setEnd(avstämdDate)
      setPeriod((period) => ({ ...period, start, end: avstämdDate }))
    } else if (type === "mon") {
      setStart(avstämdDate)
      setEnd(avstämdDate)
      setPeriod((period) => ({
        ...period,
        start: avstämdDate,
        end: avstämdDate,
      }))
    } else if (type === "ttm") {
      const lastYear = nextMonth(avstämd - 100)
      const start = new Date(
        parseInt(lastYear.toString().slice(0, 4)),
        parseInt(lastYear.toString().slice(4, 6)) - 1,
        1
      )
      setStart(start)
      setEnd(avstämdDate)
      setPeriod((period) => ({
        ...period,
        start: start,
        end: avstämdDate,
      }))
    }
  }

  return (
    <Grid item>
      <List>
        <ListItem>
          <Button
            size="small"
            fullWidth
            variant="contained"
            onClick={() => handleShortPeriod("acc")}
          >
            Ackumulerat
          </Button>
        </ListItem>
        <ListItem>
          <Button
            size="small"
            fullWidth
            variant="contained"
            onClick={() => handleShortPeriod("ttm")}
          >
            Rullande 12M
          </Button>
        </ListItem>
        <ListItem>
          <Button
            size="small"
            fullWidth
            variant="contained"
            onClick={() => handleShortPeriod("mon")}
          >
            Senaste månaden
          </Button>
        </ListItem>

        <ListItem>
          <KeyboardDatePicker
            inputVariant="filled"
            views={["year", "month"]}
            minDate={min}
            maxDate={max}
            value={start}
            onChange={(date) => setStart(date)}
            openTo="month"
            format="yyyy-MM"
            label="Från"
            variant="inline"
            autoOk
          />
        </ListItem>
        <ListItem>
          <KeyboardDatePicker
            inputVariant="filled"
            views={["year", "month"]}
            minDate={min}
            maxDate={max}
            value={end}
            onChange={(date) => setEnd(date)}
            openTo="month"
            format="yyyy-MM"
            label="Till"
            variant="inline"
            autoOk
          />
        </ListItem>
        <ListItem>
          <Grid container justify="center">
            <Button
              size="large"
              variant="contained"
              fullWidth
              onClick={handlePeriod}
            >
              Bekräfta
            </Button>
          </Grid>
        </ListItem>
      </List>
    </Grid>
  )
}

const GetData = ({ financialyears }) => {
  const updateData = useFunction("updateData")
  const [checked, setChecked] = useState(
    financialyears.map((y, i) => {
      if (i === 0 || i === 1) return { yid: y.id, c: true }
      return { yid: y.id, c: false }
    })
  )
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { cid } = useParams()

  const handleGetData = async () => {
    if (!open) {
      setOpen(true)
    } else {
      setOpen(false)
      setLoading(true)
      const update = checked.map((year) => {
        if (!year.c) return null
        return updateData({ cid, yid: year.yid })
      })
      await Promise.all(update)
      setLoading(false)
    }
  }

  const handleCheck = (yid, c) => {
    setChecked((checked) =>
      checked.map((year) => (yid === year.yid ? { ...year, c } : year))
    )
  }

  const getLabel = (year) => {
    const fromY = year.from.slice(0, 4)
    const toY = year.to.slice(0, 4)
    return fromY === toY ? toY : `${fromY} - ${toY}`
  }

  return (
    <>
      <Grid item>
        <List>
          <ListItem>
            <Grid container justify="center">
              <Button
                onClick={handleGetData}
                variant="contained"
                disabled={loading}
                fullWidth
                size="large"
              >
                Hämta data
              </Button>
            </Grid>
          </ListItem>
        </List>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Hämta data</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Välj räkenskapsår</FormLabel>
            <FormGroup>
              {financialyears.map((year, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={checked[i].c}
                        onChange={(e, c) => handleCheck(year.id, c)}
                      />
                    }
                    label={getLabel(year)}
                  />
                )
              })}
            </FormGroup>
            <Button onClick={handleGetData} variant="contained" size="large">
              Hämta
            </Button>
          </FormControl>
        </DialogContent>
      </Dialog>
    </>
  )
}

const FinancialStatementOptions = () => {
  const [state, dispatch] = useContext(OptionsContext)
  const [period] = useContext(PeriodContext)
  const { end } = period
  const to = parseInt(format(end, "yyyyMM"))
  const [value, setValue] = useState("bud")
  const [nivå, setNivå] = useState(false)
  const [budgetId, setBudgetId] = useState(null)
  const { cid } = useParams()
  const handleChange = (e, selected) => {
    const value = selected.props.value
    setValue(value)
    dispatch({ type: "SET_JMF", payload: value })
  }
  const [budgets, loading] = useCollectionData(
    db.collection(`companies/${cid}/budgets`).where("from", "<=", to)
  )
  const handleNivå = (e, selected) => {
    const value = selected.props.value
    setNivå(value)
    dispatch({ type: "SET_NIVÅ", payload: value })
  }
  useEffect(() => {
    if (budgetId) {
      dispatch({ type: "SET_BUDGET", payload: budgetId })
    }
  }, [budgetId, dispatch])
  useEffect(() => {
    if (!state?.jmf) {
      dispatch({ type: "SET_JMF", payload: "bud" })
      dispatch({ type: "SET_MIN", payload: false })
    }
  }, [state, dispatch])

  return (
    <Grid item>
      <List>
        <Route path="/c/:cid/rr">
          <ListItem>
            <FormControl variant="filled" fullWidth>
              <InputLabel>Jämförelse</InputLabel>
              <Select value={value} onChange={handleChange}>
                <MenuItem value="bud">Budget</MenuItem>
                <MenuItem value="fgå">Föregående år</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
          {!loading && budgets.length && value === "bud" ? (
            <ListItem>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Välj budget</InputLabel>
                <Select
                  value={budgetId}
                  onChange={(e, sel) => setBudgetId(sel.props.value)}
                >
                  {budgets.map((budget) => {
                    return (
                      <MenuItem value={budget.id} key={budget.id}>
                        {budget.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </ListItem>
          ) : null}
        </Route>
        <ListItem>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Nivå</InputLabel>
            <Select value={nivå} onChange={handleNivå}>
              <MenuItem value={false}>Kontonivå</MenuItem>
              <MenuItem value={true}>Minimerat</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
      </List>
    </Grid>
  )
}

const AvvikelseOptions = () => {
  const [state, dispatch] = useContext(OptionsContext)
  const [period] = useContext(PeriodContext)
  const { end } = period
  const to = parseInt(format(end, "yyyyMM"))
  const avvikelseRef = useRef([])
  const [value, setValue] = useState("bud")
  const [avvikelse, setAvvikelse] = useState("")
  const [compressed, setCompressed] = useState(false)
  const [budgetId, setBudgetId] = useState("")

  const { cid } = useParams()

  const [budgets, loading] = useCollectionData(
    db.collection(`companies/${cid}/budgets`).where("from", "<=", to)
  )
  const handleChange = (e, selected) => {
    const value = selected.props.value
    avvikelseRef.current = []
    setValue(value)
    dispatch({ type: "SET_JMF", payload: value })
    setAvvikelse((avvikelse) => -avvikelse)
  }
  useEffect(() => {
    avvikelseRef.current = []
    if (budgetId) {
      avvikelseRef.current = []
      dispatch({ type: "SET_BUDGET", payload: budgetId })
    }
    setAvvikelse((avvikelse) => avvikelse)
  }, [budgetId, dispatch])

  useEffect(() => {
    if (!state?.jmf) {
      dispatch({ type: "SET_JMF", payload: "bud" })
    }
  }, [state, dispatch])
  useEffect(() => {
    if (!parseInt(avvikelse)) {
      avvikelseRef.current.forEach((account) => {
        account.ref.style.background = ""
      })
    } else {
      avvikelseRef.current.forEach((account) => {
        if (
          parseInt(account.avvikelse) > parseInt(Math.abs(avvikelse)) ||
          -parseInt(account.avvikelse) > parseInt(Math.abs(avvikelse))
        ) {
          account.ref.style.background =
            "linear-gradient(31deg, rgba(255,0,0,0.35) 0%, rgba(237,0,0,0.25) 100%"
        } else {
          account.ref.style.background = ""
        }
      })
    }
  }, [avvikelse, avvikelseRef])

  const addRef = (ref, account, avvikelse) => {
    if (!avvikelseRef.current.some((row) => row.account === account))
      avvikelseRef.current = [
        ...avvikelseRef.current,
        { ref, account, avvikelse },
      ]
    setAvvikelse((a) => -a)
  }
  useEffect(() => {
    dispatch({ type: "ADD_REF_FUNC", payload: addRef })
  }, [dispatch])

  const handleRowSize = (e, selected) => {
    const value = selected?.props?.value
    dispatch({ type: "SET_COMPRESSED", payload: value })
    setCompressed(value)
  }

  const handleBudget = (e, sel) => {
    setBudgetId(sel.props.value)
    setAvvikelse((avvikelse) => -avvikelse)
  }

  return (
    <Grid item>
      <List>
        <ListItem>
          <TextField
            value={avvikelse}
            label="Avvikelse"
            variant="filled"
            onChange={(value) => setAvvikelse(value)}
            autoComplete="off"
            name="numberformat"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </ListItem>
        <ListItem>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Jämförelse</InputLabel>
            <Select value={value} onChange={handleChange}>
              <MenuItem value="bud">Budget</MenuItem>
              <MenuItem value="fgå">Föregående år</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        {!loading && budgets.length && value === "bud" ? (
          <ListItem>
            <FormControl variant="filled" fullWidth>
              <InputLabel>Välj budget</InputLabel>
              <Select value={budgetId} onChange={handleBudget}>
                {budgets.map((budget) => {
                  return (
                    <MenuItem key={budget.id} value={budget.id}>
                      {budget.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </ListItem>
        ) : null}
        <ListItem>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Radstorlek</InputLabel>
            <Select value={compressed} onChange={handleRowSize}>
              <MenuItem value={false}>Normal</MenuItem>
              <MenuItem value={true}>Liten</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
      </List>
    </Grid>
  )
}

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.floatValue)
      }}
      thousandSeparator=" "
      allowNegative={false}
      isNumericString
      suffix=" kr"
    />
  )
}
