import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"

const firebaseConfig = {
  dev: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
    authDomain: "sfb-web-5fac4.firebaseapp.com",
    databaseURL: "https://sfb-web-5fac4.firebaseio.com",
    projectId: "sfb-web-5fac4",
    storageBucket: "sfb-web-5fac4.appspot.com",
    messagingSenderId: "980879841836",
    appId: "1:980879841836:web:647aa256be0a19cd604f89",
    measurementId: "G-S4N5RYQJCQ",
  },
  prod: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
    authDomain: "sfbyran.firebaseapp.com",
    databaseURL: "https://sfbyran.firebaseio.com",
    projectId: "sfbyran",
    storageBucket: "sfbyran.appspot.com",
    messagingSenderId: "130758524017",
    appId: "1:130758524017:web:a2cbc014217d1122b0efd0",
    measurementId: "G-9MQE2NL19W",
  },
}
firebase.initializeApp(
  process.env.NODE_ENV === "development"
    ? firebaseConfig.dev
    : firebaseConfig.prod
)
//firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
firebase.firestore()
firebase.functions()
firebase.storage()

export default firebase
//export const db = firebase.firestore()
//export const auth = firebase.auth()
//export const functions = firebase.functions()

// const firebaseConfigDev = {
//    apiKey: "AIzaSyCyVs4eKE1eRtGU0Or9dMndU1sHx0YrcUY",
//    authDomain: "sfb-web-5fac4.firebaseapp.com",
//    databaseURL: "https://sfb-web-5fac4.firebaseio.com",
//    projectId: "sfb-web-5fac4",
//    storageBucket: "sfb-web-5fac4.appspot.com",
//    messagingSenderId: "980879841836",
//    appId: "1:980879841836:web:647aa256be0a19cd604f89",
//    measurementId: "G-S4N5RYQJCQ"
// };

// const firebaseConfigProd = {
//    apiKey: "AIzaSyDTI-fy6giKuBGYL4wOozt1DuWaTLfaAa8",
//    authDomain: "smaforetagsbyran.firebaseapp.com",
//    databaseURL: "https://smaforetagsbyran.firebaseio.com",
//    projectId: "smaforetagsbyran",
//    storageBucket: "smaforetagsbyran.appspot.com",
//    messagingSenderId: "97166215184",
//    appId: "1:97166215184:web:73d6d20aec6bc72b74e295",
//    measurementId: "G-HL4WFJEGT7"
//  };
