import {
  blue,
  green,
  lime,
  orange,
  purple,
  red,
} from "@material-ui/core/colors"

export const options = {
  res: {
    backgroundColor: blue[500],
    borderColor: "rgba(0,0,0,0.5)",
    borderWidth: 1,
  },
  oms: {
    backgroundColor: green[500],
    borderColor: "rgba(0,0,0,0.5)",
    borderWidth: 1,
  },
  ebitda: {
    backgroundColor: purple[500],
    borderColor: "rgba(0,0,0,0.5)",
    borderWidth: 1,
  },
  bv: {
    backgroundColor: lime[500],
    borderColor: "rgba(0,0,0,0.5)",
    borderWidth: 1,
  },
  pk: {
    backgroundColor: orange[500],
    borderColor: "rgba(0,0,0,0.5)",
    borderWidth: 1,
  },
  kost: {
    backgroundColor: red[500],
    borderColor: "rgba(0,0,0,0.5)",
    borderWidth: 1,
  },
}
