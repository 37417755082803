import React, { useState, useEffect } from "react"
import firebase from "../config/firebaseConfig"
import Page from "../company/CompanyRouter/Components/Page"
import { Grid, TextField, Button, Typography } from "@material-ui/core"
import { useFunction } from "../hooks/customHooks.ts"
import { Redirect } from "react-router-dom"

const useKeyExists = (userKey) => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const getNewUser = useFunction("getNewUser")
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const validUserKey = await getNewUser({
          userKey: userKey,
        })
        setResponse(validUserKey.data)
      } finally {
        setLoading(false)
      }
    }
    if (!response) fetchData()
  }, [userKey, getNewUser, response])
  return [response, loading]
}

const SignUp = ({ match }) => {
  const userKey = match.params.key
  const [userAdded, setUserAdded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userData, userLoading] = useKeyExists(userKey)
  const [error, setError] = useState("")
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  })
  const addUser = useFunction("addUser")

  useEffect(() => {
    if (data.email === "" && userData)
      setData((data) => ({ ...data, email: userData.email }))
  }, [userData, data])

  if (!userLoading && !userData) return <p>Felaktig länk</p>

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const { firstName, lastName, email, password, password2 } = data
    try {
      if (password !== password2) throw new Error("Lösenordet matchar inte")
      const displayName = `${firstName} ${lastName}`
      const user = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
      const uid = user.user.uid
      await addUser({
        userKey,
        uid,
        firstName,
        lastName,
        displayName,
        email,
      })
      setUserAdded(true)
    } catch (err) {
      console.error(err.message)
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (value, field) => {
    setError("")
    setData((data) => ({ ...data, [field]: value }))
  }

  if (userAdded) {
    return <Redirect to="/companies" />
  }

  return (
    <Page title="Skapa användare" maxWidth="sm">
      {error && (
        <Grid container justify="center">
          <Typography variant="overline" color="error" align="center">
            {error}
          </Typography>
        </Grid>
      )}
      <form onSubmit={handleSubmit}>
        <Grid spacing={3} direction="row" container>
          <Grid item xs={12}>
            <TextField
              disabled={loading}
              fullWidth
              type="email"
              label="Mail"
              required
              autoComplete="off"
              variant="outlined"
              value={data.email}
              onChange={(e) => handleChange(e.currentTarget.value, "email")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={loading}
              label="Förnamn"
              fullWidth
              required
              variant="outlined"
              autoComplete="given-name"
              value={data.firstName}
              onChange={(e) => handleChange(e.currentTarget.value, "firstName")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={loading}
              label="Efternamn"
              fullWidth
              required
              variant="outlined"
              autoComplete="family-name"
              value={data.lastName}
              onChange={(e) => handleChange(e.currentTarget.value, "lastName")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={loading}
              fullWidth
              type="password"
              label="Lösenord"
              autoComplete="new-password"
              required
              variant="outlined"
              value={data.password}
              onChange={(e) => handleChange(e.currentTarget.value, "password")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={loading}
              type="password"
              autoComplete="new-password"
              fullWidth
              label="Lösenord igen"
              required
              variant="outlined"
              value={data.password2}
              onChange={(e) => handleChange(e.currentTarget.value, "password2")}
            />
          </Grid>
          <Grid container item xs justify="flex-end">
            <Button type="submit" variant="contained" disabled={loading}>
              Skapa
            </Button>
          </Grid>
        </Grid>
      </form>
    </Page>
  )
}

export default SignUp
