import React from "react"

const NoCompanies = () => {
  return (
    <div>
      <h1>Inga företag hittades...</h1>
      <i className="material-icons">sentiment_dissatisfied</i>
    </div>
  )
}

export default NoCompanies
