import { TableCell, TableCellProps } from "@material-ui/core"
import { blue } from "@material-ui/core/colors"
import React, { useContext, useEffect, useRef, useState } from "react"
import { CellStoreContext } from "../Context/CellStore"

export const Cell = ({ id }: { id: string }) => {
  const { cellsRef, grid, addCell } = useContext(CellStoreContext)
  const [value, setValue] = useState<number | string>("")
  const [selected, setSelected] = useState(false)

  const selectedRef = useRef(selected)

  const style = {
    ...defaultStyles,
    ...(selected && selectStyles),
  }

  const move = (direction: "r" | "l" | "u" | "d") => {
    const cells = cellsRef.current
    console.log({ cells })
    switch (direction) {
      case "r":
        let col
        const row = grid.findIndex((r) => {
          col = r.indexOf(id)
          if (col >= 0) return true
          return false
        })
        let newId
        if (grid[row].length > col + 1) {
          newId = grid[row][col + 1]
        } else if (grid.length > row + 1) {
          newId = grid[row + 1][0]
        } else {
          break
        }
        console.log(cells[newId])
        cells[newId].select()
        break
    }
  }

  const select = () => {
    console.log("Select")
    selectedRef.current = true
    setSelected(true)
  }

  const blur = () => {
    selectedRef.current = false
    setSelected(false)
  }

  const onMouseDown = () => {
    const cells = cellsRef.current
    Object.values(cells).forEach((cell) => cell.selected && cell.blur())
    selectedRef.current = true
    setSelected(true)
  }

  const props: TableCellProps = {
    style,
    onMouseDown,
  }

  const cell: CellType = {
    get value() {
      return value
    },
    set value(v: string | number) {
      setValue(v)
    },
    select,
    get selected() {
      return selectedRef.current
    },
    blur,
    move,
  }

  useEffect(() => {
    const cells = cellsRef.current
    if (!Boolean(cells?.[id])) {
      addCell(id, cell)
    }
  }, [addCell, cell, cellsRef, id])

  return <TableCell {...props}>{value}</TableCell>
}

const defaultStyles = {
  padding: 0,
  borderLeft: "1px solid rgba(0,0,0,0.05)",
  height: 32,
}
const selectStyles = {
  backgroundColor: blue[50],
  boxShadowBottom: `inset 0 -1px 0 0 ${blue[500]}`,
  boxShadowTop: `inset 0 1px 0 0 ${blue[500]}`,
  boxShadowLeft: `inset 1px 0 0 0 ${blue[500]}`,
  boxShadowRight: `inset -1px 0 0 0 ${blue[500]}`,
}

export interface CellType {
  value: number | string
  select: () => void
  selected: boolean
  blur: () => void
  move: (direction: "r" | "l" | "u" | "d") => void
}
