import React, { useContext, useState } from "react"
import { CompanyContext } from "../../../App"
import {
  Button,
  Divider,
  Fade,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core"
import { Company } from "../../../hooks/Types"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import { KeyboardDatePicker } from "@material-ui/pickers"
import firebase from "../../../config/firebaseConfig"

const AvstämdSettings = () => {
  const company = useContext(CompanyContext) as Company
  const avstämd = company?.avstämd
  const cid = company.cid
  const financialyears = [...company.financialyears].reverse()
  const setAvstämd = firebase.functions().httpsCallable("setAvstamd")
  const [loading, setLoading] = useState(false)

  const months = financialyears.map(
    (y) =>
      createMonthArray(
        parseInt(y.from.slice(0, 6)),
        parseInt(y.to.slice(0, 6))
      ) as number[]
  )

  const formatMonth = (month: number, f: string | false = "MMM yyyy") => {
    const monthString = month.toString()
    const y = parseInt(monthString.slice(0, 4))
    const m = parseInt(monthString.slice(4, 6)) - 1
    const date = new Date(y, m, 1)
    return f ? format(date, f, { locale: sv }) : date
  }
  const minDate = formatMonth(months[0][0], false) as Date
  const maxDate = formatMonth(
    months[months.length - 1][months[months.length - 1].length - 1],
    false
  ) as Date
  const avstämdDate = avstämd && (formatMonth(avstämd, false) as Date)
  const [value, setValue] = useState(avstämdDate || null)
  const handleConfirm = async () => {
    if (value instanceof Date && !isNaN(value.getTime())) {
      const month = parseInt(format(value, "yyyyMM"))
      setLoading(true)
      await setAvstämd({ cid, month })
      setLoading(false)
    }
  }

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Avstämd period</Typography>
        <Divider light />
      </Grid>

      <Grid item xs={12} container>
        <KeyboardDatePicker
          inputVariant="filled"
          views={["year", "month"]}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(date) => setValue(date)}
          openTo="month"
          format="yyyy-MM"
          label="Avstämt till"
          variant="inline"
        />
        <Button
          size="large"
          variant="contained"
          disabled={loading}
          style={{ marginLeft: 16 }}
          onClick={handleConfirm}
        >
          Bekräfta
        </Button>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>
      </Grid>
    </Grid>
  )
}

export default AvstämdSettings

const createMonthArray = (
  currentMonth: number,
  endMonth: number,
  months = 20
) => {
  if (endMonth < currentMonth || months <= 0) return []
  const nextMonth =
    currentMonth.toString().slice(4, 6) === "12"
      ? currentMonth + 89
      : currentMonth + 1
  return [currentMonth, ...createMonthArray(nextMonth, endMonth, months - 1)]
}
