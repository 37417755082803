import {
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import React, { memo, useContext, useState } from "react"
import { TransactionMin } from "../../../../hooks/Types"
import { thousand } from "../../functions"
import { AvvikelseContext } from "../Avvikelse"
import { AccountContext } from "./AccountRow"
import { format } from "date-fns"
import "./styles.css"
import Verifikat from "./Verifikat"

const Transactions = () => {
  const { account, noteState } = useContext(AccountContext)
  const context = useContext(AvvikelseContext)
  const [ver, setVer] = useState<string | null>(null)
  const [show, setShow] = useState(false)
  const [selected, setSelected] = noteState
  const vouchers = context.vouchers.filter((voucher) =>
    voucher.transactions.some(
      (trans) =>
        trans.nr === account.nr ||
        (account.nr === 0 &&
          trans.nr >= account.accounts[0] &&
          trans.nr <= account.accounts[1])
    )
  )
  const transactions: TransactionMin[] = vouchers.reduce((a, c) => {
    const accountTransactions = c.transactions
      .map((t, i) => ({ ...t, id: i }))
      .filter(
        (transaction) =>
          transaction.nr === account.nr ||
          (account.nr === 0 &&
            transaction.nr >= account.accounts[0] &&
            transaction.nr <= account.accounts[1])
      )
    const transData = accountTransactions.map((trans) => ({
      ...trans,
      voucher: {
        date: c.date,
        id: c.id,
        text: c.text,
      },
    }))
    return [...a, ...transData]
  }, [])

  const handleClick = (verId: string) => {
    setVer(verId)
    setShow(true)
  }
  const voucher = ver && vouchers.find((v) => v.id === ver)

  const handleSelect = (bool: boolean, index: number) => {
    const selTrans = { ...transactions[index], index }
    setSelected((selected) => {
      return bool
        ? [...selected, selTrans]
        : selected.filter((t) => t.index !== selTrans.index)
    })
  }
  const sum = transactions.reduce((a, c, i) => {
    return selected.some((t) => t.index === i) ? a + c.amount : a
  }, 0)

  return (
    <>
      <TableContainer
        style={{
          maxHeight: "60vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            transition: "max-height 0.3s linear",
            maxHeight: show ? "60vh" : 0,
          }}
        >
          <Slide
            direction="left"
            in={show}
            onExited={() => setVer(null)}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <Verifikat voucher={voucher} goBack={() => setShow(false)} />
            </div>
          </Slide>
        </div>
        <div
          style={{
            transition: "max-height 0.3s linear",
            maxHeight: !Boolean(ver) ? "60vh" : 0,
          }}
        >
          <Slide
            direction="right"
            mountOnEnter
            unmountOnExit
            in={!Boolean(ver)}
          >
            <div>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {account.nr === 0 && <TableCell>Konto</TableCell>}
                    <TableCell colSpan={2}>Verifikat</TableCell>
                    <TableCell align="right">Datum</TableCell>
                    <TableCell align="right">Belopp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction, i) => {
                    return (
                      <Transaction
                        transaction={transaction}
                        showAccount={account.nr === 0}
                        handleClick={handleClick}
                        selected={selected.some(
                          (t) =>
                            t.voucher.id === transaction.voucher.id &&
                            t.amount === transaction.amount
                        )}
                        handleSelect={(bool: boolean) => handleSelect(bool, i)}
                        key={i}
                      />
                    )
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell
                      style={{
                        bottom: 0,
                        left: 0,
                        zIndex: 2,
                        position: "sticky",
                        backgroundColor: "#fafafa",
                      }}
                      colSpan={2}
                    >
                      Summa:
                    </TableCell>
                    <TableCell
                      style={{
                        bottom: 0,
                        left: 0,
                        zIndex: 2,
                        position: "sticky",
                        backgroundColor: "#fafafa",
                      }}
                      colSpan={account.nr === 0 ? 3 : 2}
                      align="right"
                    >
                      {thousand(sum)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Slide>
        </div>
      </TableContainer>
    </>
  )
}

export default Transactions

const Transaction = memo(
  ({
    transaction,
    showAccount = false,
    handleClick,
    handleSelect,
    selected = false,
  }: {
    transaction: TransactionMin
    showAccount?: boolean
    handleClick: (verId: string) => void
    handleSelect: (bool: boolean) => void
    selected?: boolean
  }) => {
    const { date } = transaction.voucher
    const { id, text } = transaction.voucher

    const d = new Date(
      parseInt(date.slice(0, 4)),
      parseInt(date.slice(4, 6)) - 1,
      parseInt(date.slice(6, 8))
    )
    return (
      <TableRow
        selected={selected}
        onClick={() => handleSelect(!selected)}
        style={{ paddingRight: 4 }}
      >
        {showAccount && <TableCell>{transaction.nr}</TableCell>}
        <TableCell onClick={() => handleClick(id)}>
          <Typography className="hover">{id}</Typography>
        </TableCell>
        <TableCell>{text.slice(1, -1)}</TableCell>
        <TableCell align="right">{format(d, "yyyy-MM-dd")}</TableCell>
        <TableCell align="right">{thousand(transaction.amount)}</TableCell>
      </TableRow>
    )
  }
)
