export default [
  {
    name: "Bas",
    id: "base",
    short: "Ba",
  },
  {
    name: "Avvikelse",
    id: "avvikelse",
    short: "Av",
  },
  {
    name: "Budgetarbetsblad",
    id: "budget",
    short: "Bu",
    dev: true,
  },
]
