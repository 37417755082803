import React, { useContext, useState } from "react"
import allModules from "../modules"
import { useFunction } from "../../../hooks/customHooks.ts"
import { useParams } from "react-router-dom"
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core"
import { green, grey, orange } from "@material-ui/core/colors"
import { CompanyContext } from "../../../App.tsx"

const UserRow = ({ user }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(null)
  const updateAccess = useFunction("updateAccess")
  const access = useContext(CompanyContext)
  const isAdmin = access?.admin
  const { displayName, email, modules, uid } = user
  const [userModules, setUserModules] = useState(modules || [])
  const { cid } = useParams()
  const handleModule = (e, mod) => {
    const moduleExists = userModules.includes(mod.id)
    if (mod?.dev && !moduleExists && !Boolean(open)) {
      setOpen(mod)
    } else {
      const newModules = moduleExists
        ? userModules.filter((m) => m !== mod.id)
        : [...userModules, mod.id]
      setUserModules(newModules)
      setOpen(null)
      updateAccess({ cid, uid, accessData: { modules: newModules } })
    }
  }

  const getColors = (mod) => {
    if (userModules?.includes(mod.id) && mod?.dev) {
      return classes.accessDev
    } else if (userModules?.includes(mod.id)) {
      return classes.access
    } else if (mod?.dev) {
      return classes.noAccessDev
    } else {
      return classes.noAccess
    }
  }

  return (
    <>
      <ListItem divider>
        <ListItemText primary={displayName} secondary={email} />
        <ListItemSecondaryAction>
          {allModules.map((mod, i) => {
            if (mod?.dev && !isAdmin) return null
            return (
              <IconButton
                key={i}
                onClick={(e) => handleModule(e, mod)}
                size="small"
              >
                <Avatar className={getColors(mod)}>{mod.short}</Avatar>
              </IconButton>
            )
          })}
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog open={Boolean(open)}>
        <DialogTitle>Testversion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Modulen "${open?.name}" är inte färdigutvecklad, vill du ändå tilldela denna
            behörighet?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} autoFocus>
            Nej
          </Button>
          <Button onClick={() => handleModule(null, open)}>Ja</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles({
  access: {
    backgroundColor: green[400],
  },
  noAccess: {
    backgroundColor: grey[400],
  },
  accessDev: {
    backgroundColor: orange[400],
  },
  noAccessDev: {
    backgroundColor: grey[300],
  },
})

const UserRows = ({ users }) =>
  users?.length && users.map((user, i) => <UserRow user={user} key={i} />)

export default UserRows
