import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core"
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { formatRelative, format, compareDesc } from "date-fns"
import { sv } from "date-fns/locale"
import { Note as NoteType } from "../../../../hooks/Types"
import { PeriodContext } from "../../CompanyApp"
import firebase from "../../../../config/firebaseConfig"
import { useParams } from "react-router-dom"
import { AccountContext } from "./AccountRow"
import FlipMove from "react-flip-move"
const db = firebase.firestore()

const Notes = () => {
  const [period] = useContext(PeriodContext)
  const { account, noteState } = useContext(AccountContext)
  const [notes, setNotes] = useState<Partial<NoteType>[]>([])
  const [selected, setSelected] = useState<boolean | number>(false)
  const [focus, setFocus] = useState(false)
  const [value, setValue] = useState("note")
  const [checked, setChecked] = useState(true)
  const [note, setNote] = useState("")
  const [selectedTrans, setSelectedTrans] = noteState
  const noteRef = useRef<HTMLInputElement>(null)
  const { cid } = useParams() as { cid: string }

  useEffect(() => {
    const from = parseInt(format(period.start, "yyyyMM"))
    const to = parseInt(format(period.end, "yyyyMM"))
    const ref = db
      .collection(`companies/${cid}/notes`)
      .where("month", ">=", from)
      .where("month", "<=", to)
      .where("account", "==", account.nr || account.name)
    ref.onSnapshot((snapshot) => {
      const notes = snapshot.docs.map((doc) => doc.data())
      setNotes(
        //@ts-ignore
        notes
          .sort((a, b) =>
            compareDesc(
              a.date?.toDate() || new Date(),
              b.date?.toDate() || new Date()
            )
          )
          .sort((a, b) => a.checked - b.checked)
      )
    })
  }, [account.name, account.nr, cid, period.end, period.start])

  const handleValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    selected: string
  ) => {
    setValue(selected)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const noteToAdd: Partial<NoteType> = {
      checked: false,
      month: parseInt(format(period.end, "yyyyMM")),
      name: firebase.auth().currentUser.displayName,
      private: checked,
      account: account.nr || account.name,
      date: new Date(),
      text: note,
      type: value,
      uid: firebase.auth().currentUser.uid,
      transactions: selectedTrans,
      for: "avvikelse",
    }
    setNotes((notes) => [noteToAdd, ...notes])
    setNote("")
    setFocus(false)
    setSelectedTrans([])
    noteRef.current.blur()
    const ref = db.collection(`companies/${cid}/notes`).doc()
    await ref.set({
      ...noteToAdd,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      id: ref.id,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextField
          inputRef={noteRef}
          onFocus={() => setFocus(true)}
          onChange={(e) => setNote(e.currentTarget.value)}
          value={note}
          variant="outlined"
          fullWidth
          label="Lägg till anteckning"
        />
      </form>
      <Collapse in={focus} mountOnEnter unmountOnExit>
        <Paper elevation={3} style={{ padding: 8 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Anteckningstyp</FormLabel>
                <RadioGroup
                  name="noteVariant"
                  value={value}
                  onChange={handleValue}
                >
                  <FormControlLabel
                    value="note"
                    control={<Radio />}
                    label="Anteckning"
                  />
                  <FormControlLabel
                    value="åtgärd"
                    control={<Radio />}
                    label="Åtgärd"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Visa endast för mig"
              />
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
      <List>
        <FlipMove>
          {notes.map((note, i) => (
            <Note
              note={note}
              handleSelect={() => setSelected(selected === i ? false : i)}
              selected={selected === i}
              key={i}
            />
          ))}
        </FlipMove>
      </List>
    </>
  )
}
export default Notes

const Note = forwardRef(
  (
    {
      note,
      handleSelect,
      selected,
    }: { note: Partial<NoteType>; handleSelect: () => void; selected: boolean },
    ref: React.LegacyRef<HTMLDivElement>
  ) => {
    const { noteState } = useContext(AccountContext)
    const [, setSelectedTrans] = noteState
    const { cid } = useParams() as { cid: string }
    const date = note?.date
      ? note.date instanceof Date
        ? note.date
        : //@ts-ignore
          note.date.toDate()
      : new Date()
    const secondary = `${note.name}, ${formatRelative(date, new Date(), {
      locale: sv,
    })}`

    const handleChecked = async (checked: boolean) => {
      await db.doc(`companies/${cid}/notes/${note.id}`).update({
        checked,
      })
    }

    const handleNoteTrans = () => {
      handleSelect()
      if (!selected) {
        setSelectedTrans(note.transactions)
      } else {
        setSelectedTrans([])
      }
    }

    return (
      <div ref={ref}>
        <ListItem
          divider
          selected={selected}
          disabled={note.checked}
          onDoubleClick={handleNoteTrans}
        >
          <ListItemText primary={note.text} secondary={secondary} />
          <ListItemSecondaryAction>
            <Checkbox
              checked={note.checked}
              onChange={(e) => handleChecked(e.target.checked)}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    )
  }
)
