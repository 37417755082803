import React, { useState } from "react"
import Page from "./CompanyRouter/Components/Page"
import { TextField, Button, Grid, Typography } from "@material-ui/core"
import { useFunction } from "../hooks/customHooks.ts"
import Success from "../animations/Success"

const CLIENT_KEY = "1nLHQYEx17Ph"

const AddCompany = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [company, setCompany] = useState(null)
  const [api, setApi] = useState("")
  const addCompany = useFunction("addCompany")
  const handleChange = (e) => {
    setError(false)
    setApi(e.target.value)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(false)
    setLoading(true)
    try {
      const companyData = await addCompany({ api })
      setLoading(false)
      setCompany(companyData.data)
    } catch {
      setError("Felaktig api-nyckel")
    }
    setLoading(false)
  }
  return (
    <Page title="Lägg till företag" maxWidth="sm">
      {company ? (
        <Success text={`${company.name} tillagt`} />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} justify="center" alignItems="center">
            <Typography
              variant="caption"
              align="center"
              color="textSecondary"
            >{`Använd koden "${CLIENT_KEY}" för att hitta integrationen`}</Typography>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="API-nyckel"
                disabled={loading}
                error={error}
                helperText={error}
                onChange={handleChange}
              />
            </Grid>
            <Button
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              Lägg till
            </Button>
          </Grid>
        </form>
      )}
    </Page>
  )
}

export default AddCompany
