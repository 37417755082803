import { Divider, Grid, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { AccountContext } from "./AccountRow"
import Notes from "./Notes"
import Transactions from "./Transactions"

const AccountData = () => {
  const { account } = useContext(AccountContext)

  return (
    <Grid
      container
      spacing={3}
      style={{
        paddingTop: 8,
        paddingBottom: 8,
        transition: "max-height 1s ease-in-out 1s",
      }}
    >
      <Grid item xs={12}>
        <Typography align="center" variant="h5">
          {account.name}
        </Typography>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={7}>
        <Transactions />
      </Grid>
      <Grid item xs={5} style={{ maxHeight: "60vh", overflowY: "scroll" }}>
        <Notes />
      </Grid>
    </Grid>
  )
}

export default AccountData
