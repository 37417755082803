import React, { useEffect, useState, useCallback } from "react"
import Page from "../company/CompanyRouter/Components/Page"
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Card,
  CardContent,
  Collapse,
  makeStyles,
  ListSubheader,
  LinearProgress,
  Popper,
  Button,
  ListItemIcon,
  TextField,
  Dialog,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core"
import AutoComplete from "@material-ui/lab/Autocomplete"
import { useFunction } from "../hooks/customHooks.ts"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"
import { green, red } from "@material-ui/core/colors"
import allModules from "../company/CompanyRouter/modules"

const Admin = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const getUsers = useFunction("adminGetUsers")

  const getData = useCallback(async () => {
    setLoading(true)
    const users = await getUsers()
    setUsers(users.data)
    setLoading(false)
  }, [getUsers])

  useEffect(() => {
    if (!users.length && !loading) {
      getData()
    }
  }, [users, getUsers, loading, getData])

  return (
    <Page title="Admin inställningar">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Användare</Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            {users.map((user, i) => {
              return <User user={user} getData={getData} key={i} />
            })}
          </List>
        </Grid>
      </Grid>
    </Page>
  )
}

export default Admin

export const User = ({ user, getData }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const toggleOpen = () => setOpen((open) => !open)
  const [anchorEl, setAnchorEl] = useState(null)
  const deleteUser = useFunction("adminDeleteUser")

  const handleDeleteUser = async (e) => {
    if (!Boolean(anchorEl)) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
      setLoading(true)
      const res = await deleteUser({ uid: user.uid })
      setLoading(false)
      if (res) getData()
    }
  }

  return (
    <>
      <ListItem divider onDoubleClick={toggleOpen}>
        <ListItemAvatar>
          <Avatar />
        </ListItemAvatar>
        <ListItemText primary={user.displayName} secondary={user.email} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={handleDeleteUser}>
            <DeleteIcon />
            {loading && (
              <CircularProgress size={42} className={classes.fabProgress} />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} mountOnEnter>
        <UserData user={user} />
      </Collapse>
      <Popper open={Boolean(anchorEl)} placement="left" anchorEl={anchorEl}>
        <Paper elevation={3}>
          <Grid container justify="space-between">
            <Grid item xs={12}>
              <Typography align="center" variant="subtitle1">
                Är du säker?
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                onClick={handleDeleteUser}
                className={classes.delete}
              >
                Ja
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth onClick={() => setAnchorEl(null)}>
                Nej
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </>
  )
}

const UserData = ({ user }) => {
  const adminGetAccess = useFunction("adminGetAccess")
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(false)
  const [addCompany, setAddCompany] = useState(null)
  const adminAddAccess = useFunction("adminAddAccess")

  const getData = useCallback(async () => {
    setLoading(true)
    setInit(true)
    const companies = await adminGetAccess({ uid: user.uid })
    setCompanies(companies.data)
    setLoading(false)
  }, [user, setLoading, setCompanies, adminGetAccess])

  useEffect(() => {
    if (!companies.length && !loading && !init) {
      getData()
    }
  }, [companies, adminGetAccess, loading, user, getData, init])

  const handleAddCompany = () => {
    setAddCompany(true)
  }

  const handleChange = async (e, value, reason) => {
    if (reason === "select-option") {
      const uid = user.uid
      const cid = value.cid
      setAddCompany(false)
      await adminAddAccess({ uid, cid })
      getData()
    }
  }

  return (
    <>
      <List
        disablePadding
        subheader={<ListSubheader component="div">Företag</ListSubheader>}
      >
        {!loading ? (
          companies.map((company, i) => (
            <Company company={company} user={user} getData={getData} key={i} />
          ))
        ) : (
          <LinearProgress />
        )}
        <ListItem divider>
          <ListItemIcon>
            <IconButton onClick={handleAddCompany}>
              <AddIcon />
            </IconButton>
          </ListItemIcon>
        </ListItem>
      </List>
      <Dialog
        fullWidth
        open={addCompany}
        maxWidth="sm"
        onClose={() => setAddCompany(false)}
      >
        <AddCompanyAccess handleChange={handleChange} access={companies} />
      </Dialog>
    </>
  )
}

export const AddCompanyAccess = ({ handleChange, access }) => {
  const [allCompanies, setAllCompanies] = useState([])
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const adminGetCompanies = useFunction("adminGetCompanies")

  const getData = useCallback(async () => {
    setLoading(true)
    const companies = (await adminGetCompanies()).data
    setAllCompanies(companies)
    setCompanies(
      companies.filter(
        (company) =>
          company?.ver === 1 && !access.some((c) => c.cid === company.cid)
      )
    )
    setLoading(false)
  }, [adminGetCompanies, access])

  useEffect(() => {
    if (!companies.length && !loading && !allCompanies.length) {
      getData()
    }
  }, [companies, allCompanies, loading, getData])

  return (
    <Card>
      <CardContent>
        <AutoComplete
          fullWidth
          loading={loading}
          options={companies}
          onChange={handleChange}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} fullWidth variant="outlined" />
          )}
        />
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  fabProgress: {
    color: red[300],
    position: "absolute",
    // top: -6,
    // left: -6,
    zIndex: 1,
  },
  selected: {
    backgroundColor: green[500],
  },
  delete: {
    color: red[500],
  },
}))

export const Company = ({ company, user, getData }) => {
  const adminDeleteAccess = useFunction("adminDeleteAccess")
  const adminSetModules = useFunction("adminSetModules")
  const adminSetRole = useFunction("adminSetRole")
  const classes = useStyles()
  const [modules, setModules] = useState(company?.modules || [])
  const cid = company.cid
  const uid = user.uid

  const handleDelete = async (cid) => {
    await adminDeleteAccess({ cid, uid: uid })
    await getData()
  }

  const handleModule = async (cid, modules, module) => {
    const newModules = modules.includes(module)
      ? modules.filter((mod) => mod !== module)
      : [...modules, module]
    setModules(newModules)
    await adminSetModules({ cid, uid, modules: newModules })
  }

  const handleUserType = async (e, checked) => {
    await adminSetRole({ cid, uid, role: checked })
    await getData()
  }
  return (
    <ListItem divider>
      <ListItemText primary={company.name} secondary={company.orgnr} />
      <ListItemSecondaryAction>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={company?.role === "konsult"}
              onChange={handleUserType}
            />
          }
          label="Konsult"
          labelPlacement="bottom"
        />
        {allModules.map((module, i) => {
          return (
            <IconButton
              key={i}
              edge="end"
              onClick={() => handleModule(cid, modules, module.id)}
            >
              <Avatar
                className={modules.includes(module.id) ? classes.selected : ""}
              >
                {module.short}
              </Avatar>
            </IconButton>
          )
        })}
        <IconButton edge="end" onClick={() => handleDelete(cid)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
