import React, { useState, useEffect } from "react"
import { useFunction } from "../../../hooks/customHooks.ts"
import UserRows from "./UserRow"
import firebase from "../../../config/firebaseConfig"
import { useParams } from "react-router-dom"
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  TextField,
  Typography,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"

const UserGroup = ({ users, userType }) => {
  const [email, setEmail] = useState("")
  const [addUser, setAddUser] = useState(false)
  const addAccess = useFunction("addAccess")
  const { cid } = useParams()

  const handleAdd = async (e) => {
    if (!addUser) {
      setAddUser(true)
    } else {
      e.preventDefault()
      await addAccess({ cid, email })
      setEmail("")
      setAddUser(false)
    }
  }

  return (
    <>
      <Typography variant="h5">{userType}</Typography>
      <Divider light />
      <List>
        {users.length ? <UserRows users={users} /> : ""}
        {!addUser && (
          <IconButton onClick={handleAdd}>
            <AddIcon />
          </IconButton>
        )}
        {addUser && (
          <form onSubmit={handleAdd}>
            <TextField
              label="Mail"
              type="email"
              fullWidth
              variant="filled"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <Button type="submit" variant="contained">
              Lägg till
            </Button>
          </form>
        )}
      </List>
    </>
  )
}

const HandleUserAccess = () => {
  const { cid } = useParams()
  const [users, setUsers] = useState([])
  useEffect(() => {
    const newData = (snapshot) => {
      if (snapshot.docs.length) {
        const newUsers = snapshot.docs.map((doc) => doc.data())
        setUsers(newUsers)
      }
    }

    const unsubscribe = firebase
      .firestore()
      .collection(`companies/${cid}/access`)
      .onSnapshot(newData)

    return unsubscribe
  }, [cid])

  const konsult = users.filter((user) => user.role === "konsult")
  const user = users.filter((user) => user.role === "user")

  return (
    <Grid item container spacing={3} justify="space-between">
      <Grid item xs>
        <UserGroup users={user} userType="Användare" />
      </Grid>
      <Grid item xs>
        <UserGroup users={konsult} userType="Konsulter" />
      </Grid>
    </Grid>
  )
}

export default HandleUserAccess
