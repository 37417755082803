import React, { createContext, useEffect, useReducer, useState } from "react"
import { Route, useParams, useRouteMatch } from "react-router-dom"
import Home from "./Home/Home"
import Settings from "./Settings/index"
import "./styles.scss"
import { useContext } from "react"
import Menu from "./Menu/Menu"
import { CompanyContext } from "../../App.tsx"
import Avvikelse from "./Avvikelse/Avvikelse.tsx"
import {
  Resultatrapport,
  Balansrapport,
  ResultatrapportMin,
  BalansrapportMin,
} from "./Rapporter/Layouts"
import { BudgetPage as Budget } from "./Budget/Budget"
import { Report } from "./Report/Report"

export const PeriodContext = createContext()
export const OptionsContext = createContext()

const App = ({ setCid }) => {
  const [state, dispatch] = useReducer(OptionsReducer, {})
  const route = useRouteMatch("/c/:cid/:module")

  const { cid } = useParams()
  const company = useContext(CompanyContext)
  console.log({ company })
  useEffect(() => {
    if (company === undefined) setCid(cid)
    document.title = `${company?.name} - Småföretagsbyrån`
  }, [company, setCid, cid])

  const [period, setPeriod] = useState(null)

  useEffect(() => {
    if ((company?.cid !== cid || !period) && company) {
      const { avstämd, financialyears } = company
      const konsult = company?.role === "konsult"
      const currentYear = avstämd
        ? financialyears.find(
            (year) =>
              parseInt(year.from / 100) <= avstämd &&
              parseInt(year.to / 100) >= avstämd
          )
        : financialyears[0]
      const from = financialyears[financialyears.length - 1].from
      const min = new Date(
        parseInt(from.slice(0, 4)),
        parseInt(from.slice(4, 6)) - 1,
        1
      )
      const max = konsult
        ? new Date(
            parseInt(currentYear.to.slice(0, 4)),
            parseInt(currentYear.to.slice(4, 6)) - 1,
            parseInt(currentYear.to.slice(6, 8))
          )
        : new Date(
            parseInt(avstämd?.toString().slice(0, 4)),
            parseInt(avstämd?.toString().slice(4, 6)),
            0
          )

      const start = new Date(
        parseInt(currentYear.from.slice(0, 4)),
        parseInt(currentYear.from.slice(4, 6)) - 1,
        parseInt(currentYear.from.slice(6, 8))
      )
      const end =
        konsult && !avstämd
          ? max
          : new Date(
              parseInt(avstämd?.toString().slice(0, 4)),
              parseInt(avstämd?.toString().slice(4, 6)),
              0
            )
      setPeriod({
        start,
        end,
        min,
        max,
      })
    }
  }, [cid, company, period])

  if (!company || !period) return null
  const konsult = company?.role === "konsult"
  const avstämd = Boolean(company?.avstämd)
  const visa = konsult || avstämd
  const modules = company?.modules || []

  const shouldShowMenu = () => {
    const module = route?.params?.module
    switch (module) {
      case "rr":
        return true
      case "br":
        return true
      case "avvikelse":
        return true
      case "budget":
        return false
      case "settings":
        return false
      case undefined:
        return true

      default:
        return false
    }
  }
  const showMenu = shouldShowMenu()

  return (
    <div>
      <PeriodContext.Provider value={[period, setPeriod]}>
        <OptionsContext.Provider value={[state, dispatch]}>
          <div style={{ marginRight: showMenu ? 240 : 0 }}>
            {visa && (
              <>
                <Route path="/c/:cid/rr">
                  {state?.min ? <ResultatrapportMin /> : <Resultatrapport />}
                </Route>
                <Route path="/c/:cid/br">
                  {state?.min ? <BalansrapportMin /> : <Balansrapport />}
                </Route>
                <Route path="/c/:cid/nyckeltal">
                  <Report />
                </Route>
                <Route path="/c/:cid/avvikelse">
                  <Avvikelse />
                </Route>
                {modules.includes("budget") && (
                  <Route path="/c/:cid/budget">
                    <Budget />
                  </Route>
                )}
              </>
            )}

            {konsult && (
              <Route path="/c/:cid/settings">
                <Settings />
              </Route>
            )}
            <Route exact path="/c/:cid">
              <Home />
            </Route>
          </div>
          {showMenu && <Menu />}
        </OptionsContext.Provider>
      </PeriodContext.Provider>
    </div>
  )
}

export default App

const OptionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_JMF":
      return { ...state, jmf: action.payload }
    case "SET_NIVÅ":
      return { ...state, min: action.payload }
    case "ADD_REF_FUNC":
      return { ...state, addRef: action.payload }
    case "SET_COMPRESSED":
      return { ...state, compressed: action.payload }
    case "SET_BUDGET":
      return { ...state, budgetId: action.payload }

    default:
      return { ...state }
  }
}
