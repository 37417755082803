import { Slide, Box, Paper, Typography } from "@material-ui/core"
import { cyan } from "@material-ui/core/colors"
import React, { useState, useCallback, useEffect } from "react"

export const ConfirmEmail = ({ user }: { user: firebase.User }) => {
  const [show, setShow] = useState(true)
  const [sent, setSent] = useState(false)

  const notSentText =
    "Du har inte bekräftat din mail, klicka här för att göra det!"
  const sentText = `Ett bekräftelsemail har skickats till ${user.email}`

  const handleConfirm = useCallback(async () => {
    setSent(true)
    await user.sendEmailVerification()
    setTimeout(() => setShow(false), 4000)
  }, [user])

  useEffect(() => {
    if (
      !sent &&
      user.metadata?.creationTime === user.metadata?.lastSignInTime
    ) {
      handleConfirm()
    }
  }, [handleConfirm, sent, user.metadata])

  const style = sent
    ? {}
    : {
        cursor: "pointer",
      }
  return (
    <Slide direction="down" in={show} unmountOnExit mountOnEnter>
      <Box
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 80,
          ...style,
        }}
        onClick={handleConfirm}
      >
        <Paper style={{ backgroundColor: cyan[50] }}>
          <Typography align="center">
            {sent ? sentText : notSentText}
          </Typography>
        </Paper>
      </Box>
    </Slide>
  )
}
