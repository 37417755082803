import { TableCell, TableRow } from "@material-ui/core"
import React, { useContext, useState } from "react"
import { Cell } from "./Cells/Cell"
import { CellStoreContext } from "./Context/CellStore"

export const Grid = () => {
  const { grid } = useContext(CellStoreContext)
  return (
    <>
      {grid.map((row) => {
        return (
          <TableRow>
            {row.map((id) => {
              return <Cell id={id} key={id} />
            })}
          </TableRow>
        )
      })}
    </>
  )
}
