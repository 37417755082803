import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useContext } from "react"
import { OptionsContext } from "../CompanyApp"
import { FinancialContext } from "../Rapporter/FinancialStatement"
import { AccountGroup } from "./AvvikelseComponents"
import AccountRow from "./AvvikelseComponents/AccountRow"

const AvvikelseLayout = () => {
  return (
    <AvvikelseContainer>
      <AccountGroup accounts={[3000, 3999]} text="Omsättning" />
      <AccountGroup accounts={[4000, 4999]} text="Råvaror och förnödenheter" />
      <Sum accounts={[3000, 4999]} text="Bruttovinst" />
      <AccountGroup accounts={[5000, 6999]} text="Övriga externa kostnader" />
      <AccountGroup accounts={[7000, 7699]} text="Personalkostnader" />
      <Sum accounts={[3000, 7699]} text="EBITDA" />
      <AccountGroup accounts={[7700, 7899]} text="Avskrivningar" />
      <AccountGroup accounts={[7900, 7999]} text="Övriga rörelsekostnader" />
      <AccountGroup accounts={[8000, 8499]} text="Finansiella poster" />
      <Sum accounts={[3000, 8989]} text="Resultat" />
    </AvvikelseContainer>
  )
}

export default AvvikelseLayout

const AvvikelseContainer = ({ children }) => {
  const [state] = useContext(OptionsContext)
  const compressed = state?.compressed
  return (
    <TableContainer style={{ maxHeight: "90vh" }}>
      <Table stickyHeader size={compressed ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Konto</TableCell>
            <TableCell align="right">Utfall</TableCell>
            <TableCell align="right">Budget</TableCell>
            <TableCell align="right">Avvikelse</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}

const Sum = ({
  accounts,
  text,
}: {
  accounts: [number, number]
  text: string
}) => {
  const { data } = useContext(FinancialContext)
  const [from, to] = accounts
  const usedAccounts = data.accounts.filter(
    (account) => account.nr >= from && account.nr <= to
  )

  const sum = usedAccounts.reduce(
    (acc, curr) => acc.map((a, i) => a + (curr.values?.[i] || 0)),
    [0, 0, 0]
  )

  if (!usedAccounts.length) return null

  return (
    <AccountRow
      text={text}
      account={{ nr: 0, name: text, accounts }}
      data={sum}
    />
  )
}
