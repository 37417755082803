const formatPercent = (nStr) => {
  const percent = parseInt(nStr * 100)
  if (!percent) return "-"
  return `${percent}%`
}

export const thousand = (nStr) => {
  if (nStr?.[0] === "%") return formatPercent(nStr[1])
  nStr += ""
  const x = nStr.split(".")
  const x2 = parseFloat(x[1]) >= 0.5 ? 1 : 0
  let x1 = parseInt(x[0]) + x2
  x1 += ""
  let rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1 $2")
  }
  return x1
}

//Months
export const toMonth = (p) => parseInt(`${p.y}${p.m}`)
export const nextMonth = (month) =>
  month.toString().slice(4, 6) === "12" ? month + 89 : month + 1
