import React, { useState } from "react"
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Checkbox,
  Button,
  IconButton,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"

export const Nyckeltal = ({ setNyckeltal }) => {
  const [allNyckeltal, setAllNyckeltal] = useState([...defaultNyckeltal])
  const [checked, setChecked] = useState([])
  const [addCustom, setAddCustom] = useState(false)

  const handleChange = (id: string) => () => {
    setChecked((c) => {
      let newData = checked.includes(id)
        ? c.filter((a) => a !== id)
        : [...c, id]
      setNyckeltal(allNyckeltal.filter((n) => newData.includes(n.id)))
      return newData
    })
  }

  const add = ({ kontoFrom, kontoTo, text }) => {
    setAddCustom(false)
    setAllNyckeltal((n) => [
      ...n,
      {
        kontoFrom,
        kontoTo,
        text,
        id: text,
        total: false,
      },
    ])
    setChecked((c) => {
      const newData = [...c, text]
      setNyckeltal(allNyckeltal.filter((n) => newData.includes(n.id)))
      return newData
    })
  }

  return (
    <Grid item xs={6}>
      <Typography>Nyckeltal</Typography>
      <Divider style={{ marginBottom: 16 }} />
      <List>
        {allNyckeltal.map((nyckeltal, i) => (
          <ListItem
            key={nyckeltal.id}
            role={undefined}
            dense
            button
            onClick={handleChange(nyckeltal.id)}
            divider={i === allNyckeltal.length - 1}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                checked={checked.includes(nyckeltal.id)}
              />
            </ListItemIcon>
            <ListItemText
              primary={nyckeltal.text}
              secondary={
                nyckeltal.kontoFrom === nyckeltal.kontoTo
                  ? `${nyckeltal.kontoFrom}`
                  : `${nyckeltal.kontoFrom} - ${nyckeltal.kontoTo}`
              }
            />
          </ListItem>
        ))}
        {addCustom ? (
          <AddCustom add={add} close={() => setAddCustom(false)} />
        ) : (
          <ListItem
            role={undefined}
            dense
            button
            onClick={() => setAddCustom(true)}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Anpassad" />
          </ListItem>
        )}
      </List>
    </Grid>
  )
}

const initCustom = {
  kontoFrom: "",
  kontoTo: "",
  text: "",
}

const AddCustom = ({ add, close }) => {
  const [custom, setCustom] = useState(initCustom)
  const [isValid, setIsValid] = useState(false)
  console.log(custom)

  const handleChange = (field: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.persist()
    console.log(e.target)
    setCustom((c) => {
      const newC = {
        ...c,
        [field]: e.target.value,
      }
      if (
        newC.kontoFrom.length === 4 &&
        newC.kontoTo.length === 4 &&
        newC.text !== "" &&
        parseInt(newC.kontoFrom) <= parseInt(newC.kontoTo) &&
        ((parseInt(newC.kontoFrom) < 3000 && parseInt(newC.kontoTo) < 3000) ||
          (parseInt(newC.kontoFrom) >= 3000 && parseInt(newC.kontoTo) >= 3000))
      ) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
      return newC
    })
  }

  const handleClick = () => {
    add(custom)
  }

  return (
    <Grid
      style={{
        marginTop: 8,
        position: "relative",
      }}
      container
      spacing={2}
      xs={12}
    >
      <Grid item>
        <TextField
          fullWidth
          autoFocus
          value={custom.kontoFrom}
          onChange={handleChange("kontoFrom")}
          style={{ width: "8rem" }}
          InputLabelProps={{ shrink: true }}
          label="Från konto"
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          value={custom.kontoTo}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange("kontoTo")}
          style={{ width: "8rem" }}
          label="Till konto"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={custom.text}
          onChange={handleChange("text")}
          label="Text"
        />
      </Grid>
      <Grid item>
        <Button color="primary" disabled={!isValid} onClick={handleClick}>
          Lägg till
        </Button>
      </Grid>
      <IconButton
        size="small"
        onClick={close}
        style={{ position: "absolute", right: 0, top: 0 }}
      >
        <CloseIcon />
      </IconButton>
    </Grid>
  )
}

const defaultNyckeltal: INyckeltal[] = [
  {
    kontoFrom: 3000,
    kontoTo: 3999,
    id: "oms",
    text: "Omsättning",
    total: false,
  },
  {
    id: "ext",
    text: "Externa kostnader",
    kontoFrom: 5000,
    kontoTo: 6999,
    total: false,
  },
  {
    id: "pk",
    text: "Personalkostander",
    kontoFrom: 7000,
    kontoTo: 7699,
    total: false,
  },
  {
    id: "ebitda",
    text: "EBITDA",
    kontoFrom: 3000,
    kontoTo: 7699,
    total: false,
  },
  {
    id: "ebit",
    text: "EBIT",
    kontoFrom: 3000,
    kontoTo: 7999,
    total: false,
  },
]

export interface INyckeltal {
  kontoFrom: number
  kontoTo: number
  total: boolean
  text: string
  id: string
}
