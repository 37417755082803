import React from "react"
import { BudgetTable } from "./BudgetTable"
import { CellStore } from "./Context/CellStore"
import { Grid } from "./Grid"
// import { GridContextProvider } from "./Context/GridContext"
// import { Kontoplan } from "./Kontoplan"

export const Worksheet = () => {
  return (
    <CellStore>
      <BudgetTable>
        <Grid />
      </BudgetTable>
    </CellStore>
  )
}
